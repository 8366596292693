import { string } from "prop-types";

import EXCAVATOR from '../../../../assets/images/icons/svg/trucking-22.svg';
import BACKHOE from '../../../../assets/images/icons/svg/trucking-27.svg';
import BULLDOZER from '../../../../assets/images/icons/svg/trucking-14.svg';
import FRONT_LOADER from '../../../../assets/images/icons/svg/trucking-13.svg';
import GRADER from '../../../../assets/images/icons/svg/trucking-14.svg';
import TRENCHER from '../../../../assets/images/icons/svg/trucking-21.svg';
import MINI_EXCAVATOR from '../../../../assets/images/icons/svg/cargo.svg';
import PALLET_TRUCK from '../../../../assets/images/icons/svg/cargo.svg';
import FORKLIFT from '../../../../assets/images/icons/svg/cargo.svg';
import DUMP_TRUCK from '../../../../assets/images/icons/svg/cargo.svg';
import CRAWLER_DOZER from '../../../../assets/images/icons/svg/cargo.svg';
import CONVEYOR from '../../../../assets/images/icons/svg/cargo.svg';
import UNKNOWN from '../../../../assets/images/icons/svg/cargo.svg';

export const AssetTypeToImage = {
  'EXCAVATOR': EXCAVATOR,
  'BACKHOE': BACKHOE,
  'BULLDOZER': BULLDOZER,
  'FRONT_LOADER': FRONT_LOADER,
  'MINI_EXCAVATOR': MINI_EXCAVATOR,
  'GRADER': GRADER,
  'TRENCHER': TRENCHER,
  'PALLET_TRUCK': PALLET_TRUCK,
  'FORKLIFT': FORKLIFT,
  'DUMP_TRUCK': DUMP_TRUCK,
  'CRAWLER_DOZER': CRAWLER_DOZER,
  'CONVEYOR': CONVEYOR,
  'UNKNOWN': UNKNOWN
};
