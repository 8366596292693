import axios from "axios";
import { getHeaders } from "./api/util";

export const instance = axios.create({
  baseURL: process.env.REACT_APP_BACKEND_BASE_URL,
  timeout: 30000,
  headers: { "Content-Type": "application/json" },
});

export const getAxiosConfig = (user, data, params) => {
  const config = {
    headers: getHeaders(user),
  };

  if (data) {
    config.data = data;
  }
  if (params) {
    config.params = params;
  }
  return config;
};
