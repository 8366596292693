import { instance, getAxiosConfig } from "../axios-config";
import { AssetEventLevel } from "../models/models.ts";
import { mapToAssetEvent, mapToAsset, mapJobSite, equipmentCountOf } from "./util";
import moment from "moment";

export const getJobsites = async (orgId) => {
  const idToken = sessionStorage.getItem("token");

  if (!orgId || !idToken) {
    throw Error("Org id and token required.");
  }

  const jobsites = await instance.get(
    `/org/${orgId}/jobsites/cards`,
    getAxiosConfig({ idToken: idToken })
  );

  return {
    jobesiteSummaries: jobsites.data.map((j) => {
      return {
        metadata: mapJobSite(j.jobsite),
        status: j.jobsite.isActive,
        incidentsToday: j.eventInfo.numIncidents,
        activeEquipment: [] /* TODO */,
      };
    }),
  };
};

export const getJobsiteOverview = async (jobsiteId) => {
  const idToken = sessionStorage.getItem("token");

  if (!jobsiteId || !idToken) {
    throw Error("Token required.");
  }
  const today = moment().startOf("day");
  const fromToday = today.valueOf();
  const fromWeek = today.subtract("7d").valueOf();
  const fromMonth = today.subtract("30d").valueOf();

  const responses = await Promise.all([
    instance.get(
      `/jobsites/${jobsiteId}/events/summary`,
      getAxiosConfig({ idToken: idToken }, null, {
        from: fromToday,
      })
    ),
    instance.get(
      `/jobsites/${jobsiteId}/events/summary`,
      getAxiosConfig({ idToken: idToken }, null, {
        from: fromWeek,
      })
    ),
    instance.get(
      `/jobsites/${jobsiteId}/events/summary`,
      getAxiosConfig({ idToken: idToken }, null, {
        from: fromMonth,
      })
    ),
    instance.get(
      `/jobsites/${jobsiteId}/events`,
      getAxiosConfig({ idToken: idToken }, null, {
        from: moment().subtract("100d").valueOf(),
        count: 500,
      })
    ),
    instance.get(`/jobsites/${jobsiteId}`, getAxiosConfig({ idToken: idToken })),
  ]);

  const [dayEventSummaries, weekEventSummaries, monthEventSummaries, events, jobsite] = responses;
  return {
    jobsite: mapJobSite(jobsite.data.jobsite),
    activeEquipment: equipmentCountOf(jobsite.data.jobsiteAssets, (a) => a.isActive) /* TODO */,
    eventsSummaries: {
      day: {
        incidents: dayEventSummaries.data.numIncidents,
        warnings: dayEventSummaries.data.numWarnings,
      },
      week: {
        incidents: weekEventSummaries.data.numIncidents,
        warnings: weekEventSummaries.data.numWarnings,
      },
      month: {
        incidents: monthEventSummaries.data.numIncidents,
        warnings: monthEventSummaries.data.numWarnings,
      },
    },
    riskLevels: {
      /* TODO */ day: [],
      month: [],
      week: [],
    },
    recentEvents: events.data.events.slice(0, 10).map((e) => mapToAssetEvent(e, {})),
  };
};

export const getJobsiteActivity = async (jobsiteId) => {
  const idToken = sessionStorage.getItem("token");

  if (!jobsiteId || !idToken) {
    throw Error("Token required.");
  }

  const events = await instance.get(
    `/jobsites/${jobsiteId}/events`,
    getAxiosConfig({ idToken: idToken }, null, {
      from: moment().subtract("100d").valueOf(),
      count: 500,
    })
  );
  const mappedEvents = events.data.events.map((e) => mapToAssetEvent(e, {}));
  return {
    riskLevels: {
      /* TODO  */ day: [],
      month: [],
      week: [],
    },
    recentEvents: mappedEvents.slice(0, 10),
    incidents: mappedEvents.filter((e) => e.level === AssetEventLevel.Incident),
  };
};

export const getJobsiteAssets = async (jobsiteId) => {
  const idToken = sessionStorage.getItem("token");

  if (!jobsiteId || !idToken) {
    throw Error("Token required.");
  }

  const jobsiteDetails = await instance.get(
    `/jobsites/${jobsiteId}`,
    getAxiosConfig({ idToken: idToken })
  );
  return { assets: jobsiteDetails.data.jobsiteAssets.map(mapToAsset) };
};
