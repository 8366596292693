import { withAuthenticationRequired } from "@auth0/auth0-react";
import React from "react";
import PropTypes from 'prop-types';

export const AuthenticationGuard = ({ component }) => {
    const Component = withAuthenticationRequired(component, {
        onRedirecting: () => (
            <div className="page-layout">
                <h1>Redirecting for Auth...</h1>
            </div>
        ),
    });

    return <Component />;
};

AuthenticationGuard.propTypes = {
    component: PropTypes.node.isRequired
}