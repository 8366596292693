import { useState, useEffect, useRef } from "react";
import PropTypes, { number } from "prop-types";
import BarChart from "components/charts/BarChart";
import "chartjs-adapter-date-fns";
import { enUS } from "date-fns/locale";
import { AssetEventLevel } from "data/models/models.ts";
import colors from "assets/theme/base/colors.js";
import moment from "moment";
import "@popperjs/core";
import { Tooltip } from "@mui/material";

/**
 * NOTE: This component contains many commented-out console.log statements that can help with debugging, please do not delete
 *
 * This component comprises of the VideoSlider which is powered by ChartJS
 * We've laid a Bar data set for each video segment which is then rendered on a time-based x-axis
 *
 * The actual video playhead (the thing you drag to a time) is powered by ChartJS annotations in the form
 * of a Line annotation. The annotation is placed via an X value that is determined by the current video playing ts
 *
 * The main plugin used for the playhead interaction is "dragger" and is responsible for the following reqs:
 * - we need to force user interaction with the playhead to occur only within segments
 * - the user can drag the playhead within a segment or between segements, this is done via mousemove events
 * - the user can also click on a segment and the playhead will snap to that ts, this is done via the chart onClick listener
 *
 * There is also a tooltip that is triggered by the chart onHover event and is anchored by @constant {chartDivRef}
 * The tooltip is given the value of the hover event's corresponding timestamp
 */
const VideoSlider = ({ selectedDate, segments, videoCurrentTime, onSliderSelection }) => {
  let lastSliderLineEvent;

  const currentSelectedSegmentRef = useRef(null);
  const segmentsStateRef = useRef(segments);
  const chartRef = useRef(null);
  const sliderLineElementRef = useRef(null);
  const sliderWasGripped = useRef(false);

  const chartTooltipPosRef = useRef({
    x: 0,
    y: 0,
  });
  const chartTooltipPopperRef = useRef(null);
  const [chartTooltipText, setChartTooltipText] = useState(null);

  const chartDivRef = useRef(null);

  const findSegmentForElementEvent = (element) => {
    const barStartTime = element.$context.raw[0];
    const barEndTime = element.$context.raw[1];
    let found;
    segmentsStateRef.current.forEach((segment) => {
      if (segment.start == barStartTime && segment.end == barEndTime) {
        found = segment;
      }
    });

    return found;
  };

  const isEventInsideSegment = (mouseEventTimestamp) => {
    let found;
    segmentsStateRef.current.forEach((segment) => {
      if (segment.start <= mouseEventTimestamp && segment.end >= mouseEventTimestamp) {
        found = segment;
      }
    });

    return found;
  };

  /**
   * This function gets called after we've determined that user has mousedown
   * inside the playhead annotation and are now tracking the mousemove events
   * as diffs from current position
   * @param {The translation for the annotation in X} moveX
   * @param {The translation for the annotation in X} moveY
   */
  const drag = function (moveX, moveY) {
    const currentSliderEl = sliderLineElementRef.current;
    currentSliderEl.x += moveX;
    currentSliderEl.y += moveY;
    currentSliderEl.x2 += moveX;
    currentSliderEl.y2 += moveY;
    currentSliderEl.centerX += moveX;
    currentSliderEl.centerY += moveY;
    if (currentSliderEl.elements && currentSliderEl.elements.length) {
      for (const subEl of currentSliderEl.elements) {
        subEl.x += moveX;
        subEl.y += moveY;
        subEl.x2 += moveX;
        subEl.y2 += moveY;
        subEl.centerX += moveX;
        subEl.centerY += moveY;
        subEl.bX += moveX;
        subEl.bY += moveY;
      }
    }
  };

  /**
   * This function gets called as the videoCurrentTimestamp is updated
   * via the props - we have an objective X value gained from the ts itself
   * @param {The new position for the annotation in X} moveX
   */
  const moveTo = function (newX) {
    const currentSliderEl = sliderLineElementRef.current;
    const moveX = newX - currentSliderEl.x;
    currentSliderEl.x += moveX;
    currentSliderEl.x2 += moveX;
    currentSliderEl.centerX += moveX;
    if (currentSliderEl.elements && currentSliderEl.elements.length) {
      for (const subEl of currentSliderEl.elements) {
        subEl.x += moveX;
        subEl.x2 += moveX;
        subEl.centerX += moveX;
        subEl.bX += moveX;
      }
    }
  };

  /**
   * This conducts a few checks before allowing the playhead to get dragged
   * 1. We check if there's a currentSelectedSegment so that we can verify if the
   * mouseMove is inside a segment
   * 2. If we aren't moving inside the current segment, then we need to check if the mouse
   * is moving inside a different segment
   * 3. If we are moving inside another segment, we need to set the currentSelectedSegmentRef to that
   * 4. We check if there's a lastSliderLineEvent or if we're missing the actual playhead ref
   * (The lastSliderLineEvent tells us how much to move the playhead)
   * 5. If everything checks out, then we allow the drag to occur (only on the X)
   * @param {The chart object - this is used to determine the ts of the mousemove} chart
   * @param {The actual mousemove event} event
   * @returns
   */
  const handleElementDragging = function (chart, event) {
    // check if we're still dragging inside a segment
    const mouseMoveTs = chart.scales["x"].getValueForPixel(event.x);

    if (currentSelectedSegmentRef.current) {
      const mouseMoveTsIsInCurrentSegment =
        currentSelectedSegmentRef.current.start <= mouseMoveTs &&
        currentSelectedSegmentRef.current.end >= mouseMoveTs;

      if (!mouseMoveTsIsInCurrentSegment) {
        const mouseMoveTsIsInOtherSegment = isEventInsideSegment(mouseMoveTs);
        if (!mouseMoveTsIsInOtherSegment) {
          // handleElementDragging - not in any segment
          return;
        } else {
          // handleElementDragging - in another segment
          currentSelectedSegmentRef.current = mouseMoveTsIsInOtherSegment;
        }
      }
    }

    // console.log(`handleElementDragging - ${!lastSliderLineEvent} || ${!sliderLineElementRef.current}`)
    if (!lastSliderLineEvent || !sliderLineElementRef.current) {
      return;
    }
    const moveX = event.x - lastSliderLineEvent.x;
    const moveY = event.y - lastSliderLineEvent.y;
    drag(moveX, 0);
    lastSliderLineEvent = event;
    return true;
  };

  const handleDrag = function (chart, event) {
    if (sliderLineElementRef.current) {
      switch (event.type) {
        case "mousemove":
          return handleElementDragging(chart, event);
        case "mouseout":
        case "mouseup":
          lastSliderLineEvent = undefined;
          break;
        case "mousedown":
          // if the annotation mousedown starts in a segment, assign the last event
          // this will allow the slider to drag within the segment
          // you can log the following mouseDownTs to tell when the click starts
          // const mouseDownTs = chart.scales['x'].getValueForPixel(event.x);
          // console.log(`handleDrag - mousedown`)
          // we should always have a current selected segment
          if (currentSelectedSegmentRef.current) {
            lastSliderLineEvent = event;
          } else {
            lastSliderLineEvent = undefined;
          }
          break;
        default:
      }
    }
  };

  const dragger = {
    id: "dragger",
    beforeEvent(chart, args, options) {
      if (handleDrag(chart, args.event)) {
        args.changed = true;
        return;
      }
    },
  };

  const line = {
    type: "line",
    borderColor: "white",
    borderWidth: 3,
    borderRadius: 10,
    borderShadowColor: "black",
    shadowBlur: 3,
    scaleID: "x",
    value: moment().startOf("day").toDate(),
  };

  const initialOptions = {
    animation: false,
    responsive: true,
    indexAxis: "y",
    maintainAspectRatio: false,
    layout: {
      autoPadding: false,
      padding: 0,
    },
    animation: {
      onComplete: function (animation) {
        if (animation.initial) {
          chartRef.current = animation.chart;
        }
      },
    },
    interaction: {
      mode: "x",
    },
    events: ["mousedown", "mouseup", "mousemove"],
    onHover: (event, elements, chart) => {
      let x = chart.scales["x"].getValueForPixel(event.x);
      // console.log(`CHART onHover - ${event.type}`)
      // console.log(`CHART hovered ${elements} - at - ${moment(x).toDate()}`)

      if (event.type === "mousedown" && sliderLineElementRef) {
        // verify that we're mousedown in the slider
        const distanceFromSlider = Math.abs(event.x - sliderLineElementRef.current.x);
        const sliderWidth = sliderLineElementRef.current.options.borderWidth;
        const mouseDownIsOutsideSlider = distanceFromSlider > sliderWidth / 2;

        // we're going to clear the last event if the user starts to click outside of the slider
        // this is anticipating a drag that starts outside of the slider, which we don't want
        if (mouseDownIsOutsideSlider) {
          lastSliderLineEvent = undefined;
        } else {
          sliderWasGripped.current = true;
        }
      }

      setChartTooltipText(moment(x).format("MMM D, YYYY - hh:mm:ss A z"));
      chartTooltipPosRef.current = { x: event.x, y: event.y };
      if (chartTooltipPopperRef.current != null) {
        chartTooltipPopperRef.current.update();
      }
    },
    onClick: (event, elements, chart) => {
      let x = chart.scales["x"].getValueForPixel(event.x);
      // console.log(`CHART onClick - ${event}`)
      // console.log(`CHART clicked at - ${moment(x).toDate()}`)

      if (elements && elements.length > 0) {
        // find the segment that correlates to this click
        const segment = findSegmentForElementEvent(elements[0].element);
        if (segment && sliderLineElementRef.current) {
          // console.log(`CLICKED SEGMENT - ${segment} at time ${moment(x).toDate()}`)
          // console.log(`MOVING SLIDER TO - ${event.x}`)
          // put the annotation at this time
          currentSelectedSegmentRef.current = segment;
          sliderResetTrackerRef.current = x;
          sliderWasGripped.current = true;
          onSliderSelection(x, segment);
          moveTo(event.x);
        } else {
          // if the click "occurs" outside of a segment, continue at the latest playing ts
          sliderWasGripped.current = false;
        }
      }
    },
    plugins: {
      legend: {
        display: false,
      },
      title: {
        display: false,
      },
      annotation: {
        clip: false,
        afterDraw: (context) => {
          sliderLineElementRef.current = context.element;
        },
        enter(ctx) {
          sliderLineElementRef.current = ctx.element;
        },
        annotations: {
          line,
        },
      },
      tooltip: {
        enabled: false,
      },
    },
    scales: {
      y: {
        stacked: true,
        grid: {
          display: false,
          drawBorder: false,
        },
      },
      x: {
        type: "time",
        time: {
          unit: "hour",
          // Luxon format string
          tooltipFormat: "dd",
        },
        min: moment().startOf("day").valueOf(), // init to full day
        max: moment().endOf("day").valueOf(),
        grid: {
          display: false,
          drawBorder: false,
        },
        adapters: {
          date: {
            locale: enUS,
          },
        },
        ticks: {
          font: {
            size: 10,
            family: "Gotham",
            weight: "bold",
          },
        },
      },
    },
  };

  const chartData = {
    labels: [""],
    datasets: [],
  };

  const getEventColor = (level) => {
    switch (level) {
      case AssetEventLevel.Incident:
        return colors.dozer.red;
      case AssetEventLevel.Warning:
        return colors.dozer.yellow;
      case AssetEventLevel.Normal:
        return colors.dozer.green;
      default:
        return colors.dozer.green;
    }
  };

  const mapSegmentsToDatasets = (segments) => {
    const dataSet = [];

    segments.forEach((segment) => {
      if (segment.segmentEvents?.length) {
        segment.segmentEvents.forEach((event) => {
          dataSet.push({
            label: "",
            data: [
              [
                moment(event.eventTimestamp).valueOf(),
                moment(event.eventEndTimestamp).valueOf() || moment(segment.end).valueOf(),
              ],
            ],
            backgroundColor: getEventColor(event.level),
            borderRadius: 10,
            borderSkipped: false,
          });
        });
      }

      dataSet.push({
        label: "",
        data: [[moment(segment.start).valueOf(), moment(segment.end).valueOf()]],
        backgroundColor: colors.dozer.gray.medium,
        borderRadius: 10,
        borderSkipped: false,
      });
    });

    return dataSet;
  };

  const [options, setOptions] = useState(initialOptions);
  const [data, setData] = useState(chartData);
  // loop through segments to generate chart data.
  useEffect(() => {
    // set options and data based on props.
    if (selectedDate) {
      const updatedOptions = {
        ...options,
        scales: {
          ...options.scales,
          x: {
            ...options.scales.x,
            min: moment(selectedDate).startOf("day").valueOf(),
            max: moment(selectedDate).endOf("day").valueOf(),
          },
        },
      };

      updatedOptions.plugins.annotation.annotations.line.value = moment(selectedDate)
        .clone()
        .startOf("day")
        .utc()
        .toDate();

      setOptions(updatedOptions);

      const wipedData = {
        labels: [""],
        datasets: [],
      };
      setData(wipedData);
    }

    if (segments?.length) {
      const updatedData = {
        labels: [""],
        datasets: mapSegmentsToDatasets(segments),
      };

      segmentsStateRef.current = segments;
      setData(updatedData);

      if (segments.length > 0) {
        sliderWasGripped.current = false;
        currentSelectedSegmentRef.current = segments[0];
      }
    }
  }, [segments, selectedDate]);

  const sliderResetTrackerRef = useRef(null);
  useEffect(() => {
    if (
      sliderResetTrackerRef &&
      sliderWasGripped.current &&
      videoCurrentTime - sliderResetTrackerRef.current > 5_000
    ) {
      console.log(
        `sliderResetTrackerRef - reset gripped... ${videoCurrentTime} - ${sliderResetTrackerRef.current}`
      );
      sliderWasGripped.current = false;
    }

    if (chartRef.current && sliderLineElementRef.current && !sliderWasGripped.current) {
      const xFromTs = chartRef.current.scales["x"].getPixelForValue(videoCurrentTime);
      moveTo(xFromTs);

      const updatedOptions = {
        ...options,
      };

      // we need to update the onClick callback on every videoCurrentTime to ensure
      // the onSliderSelection function reference has the latest captured state for the asset
      updatedOptions.onClick = (event, elements, chart) => {
        let x = chart.scales["x"].getValueForPixel(event.x);
        // console.log(`CHART onClick - ${event}`)
        // console.log(`CHART clicked at - ${moment(x).toDate()}`)

        if (elements && elements.length > 0) {
          // find the segment that correlates to this click
          const segment = findSegmentForElementEvent(elements[0].element);
          if (segment && sliderLineElementRef.current) {
            // console.log(`CLICKED SEGMENT - ${segment} at time ${moment(x).toDate()}`)
            // console.log(`MOVING SLIDER TO - ${event.x}`)
            // put the annotation at this time
            currentSelectedSegmentRef.current = segment;
            sliderWasGripped.current = true;
            sliderResetTrackerRef.current = x;
            onSliderSelection(x, segment);
            moveTo(event.x);
          } else {
            // if the click "occurs" outside of a segment, continue at the latest playing ts
            sliderWasGripped.current = false;
          }
        }
      };

      updatedOptions.plugins.annotation.annotations.line.value = moment(videoCurrentTime).toDate();
      setOptions(updatedOptions);
    }
  }, [videoCurrentTime]);

  if (data) {
    return (
      <Tooltip
        title={chartTooltipText}
        placement="top"
        arrow
        PopperProps={{
          popperRef: chartTooltipPopperRef,
          anchorEl: {
            getBoundingClientRect: () => {
              return new DOMRect(
                chartTooltipPosRef.current.x + chartDivRef.current.getBoundingClientRect().x,
                chartDivRef.current.getBoundingClientRect().y,
                0,
                0
              );
            },
          },
        }}
      >
        <div style={{ height: "60px", margin: "auto" }} ref={chartDivRef}>
          <BarChart plugins={[dragger]} data={data} options={options} height={60} width={1100} />
        </div>
      </Tooltip>
    );
  }
};

export default VideoSlider;

VideoSlider.propTypes = {
  selectedDate: PropTypes.instanceOf(Date).isRequired,
  segments: PropTypes.array.isRequired, // segments within this start and end times
  videoCurrentTime: PropTypes.instanceOf(Date),
  onSliderSelection: PropTypes.func.isRequired,
};
