import { useState, useEffect } from "react";
import SoftBox from "components/SoftBox";
import { Grid, Tabs, Tab, Box, Typography } from "@mui/material";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import EventsOverview from "components/EventsOverview";
import AssetVideoFeed from "components/AssetVideoFeed";

import { getAssetOverview, getAsset } from "data/api/asset";
import { useParams, NavLink } from "react-router-dom";
import colors from "../../assets/theme/base/colors";
import _ from "lodash";
import { CameraIcon } from "../../assets/Icons.tsx";

const AssetOverview = () => {
  const [asset, setAsset] = useState(null);
  const [selectedTab, setSelectedTab] = useState(0);
  const [eventSummaries, setEventSummaries] = useState({ day: null, month: null, week: null });
  const [timelineEvents, setTimelineEvents] = useState([]);
  const [riskLevels, setRiskLevels] = useState({ day: [], month: [], week: [] });
  const [shouldLoadVideo, setShouldLoadVideo] = useState(false);
  const [isVideoPlaying, setVideoPlaying] = useState(false);

  const params = useParams();
  const { assetId } = params;

  const fetchAsset = async () => {
    let assetResponse = null;

    try {
      assetResponse = await getAssetOverview(assetId);
    } catch (error) {
      console.log("Error fetching asset", error);
    }

    if (assetResponse) {
      // const asset = _.find(dozerAssets, (entry) => {
      //   entry.asset.assetId === assetId;
      // });

      const {
        eventsSummaries: summaries,
        recentEvents: events,
        riskLevels: levels,
      } = assetResponse;
      if (summaries?.length) {
        setEventSummaries(summaries);
      }
      if (events?.length) {
        setTimelineEvents(events);
      }
      if (levels?.length) {
        setRiskLevels(levels);
      }
    }
  };

  const fetchAssetData = async () => {
    let assetResponse = null;

    try {
      assetResponse = await getAsset(assetId);
    } catch (error) {
      console.log("Error fetching asset", error);
    }

    if (assetResponse) {
      setAsset(assetResponse);
    }
  };

  useEffect(() => {
    if (assetId) {
      fetchAssetData();
      fetchAsset();
    }
  }, []);

  useEffect(() => {
    // we need to trigger the play() event after we have switched tabs to the video player tab. Otherwise the player will throw an error and won't start playing.
    if (selectedTab === 1 && !shouldLoadVideo) {
      setShouldLoadVideo(true);
      setVideoPlaying(true);
    }
  }, [selectedTab]);

  const handleSelectTab = (e, value) => {
    setSelectedTab(value);
  };

  return (
    <DashboardLayout pageTitle="Assets">
      <SoftBox
        pt={3}
        bgColor="white"
        // bgColor={selectedTab === 0 ? "white" : colors.dozer.gray.dark}
      >
        <Grid container direction="column" sx={{ width: "100%", height: "100%" }}>
          <Grid container sx={{ height: "100%" }}>
            <Grid container item xs={12} alignItems="center" sx={{ paddingLeft: "15px" }}>
              <NavLink to={`/assets`} style={{ display: "flex" }}>
                <ArrowBackIcon sx={{ fill: colors.dozer.gray.light }} fontSize="large" />
              </NavLink>
              <Typography
                variant="h4"
                fontWeight="light"
                color={colors.dozer.gray.dark}
                ml={1.5}
                xs={12}
              >
                {asset && asset.nickName}
              </Typography>
              <Typography
                variant="caption"
                textTransform="uppercase"
                fontWeight="light"
                color={colors.dozer.gray.medium}
                ml={1.5}
              >
                {asset && asset.year} {asset && asset.model} {asset && asset.typeInfo.type}
              </Typography>
            </Grid>
            <Grid
              item
              display="flex"
              flexDirection="column"
              justifyContent="space-between"
              xs={12}
              sx={{ height: "600px" }}
            >
              <Tabs
                value={selectedTab}
                onChange={handleSelectTab}
                sx={{
                  mt: 1,
                  ml: 7,
                  width: "fit-content",
                  backgroundColor: "transparent !important",
                  "& .MuiTabs-indicator": {
                    display: "none",
                  },
                }}
              >
                <Tab label="Overview" value={0} sx={{ mr: 3 }} />
                <Tab
                  label="Video Feed"
                  iconPosition="end"
                  icon={
                    <CameraIcon
                      fill={selectedTab === 1 ? colors.dozer.gray.dark : colors.dozer.gray.light}
                    />
                  }
                  value={1}
                />
              </Tabs>
              <Box ml={8} display={selectedTab === 0 ? "block" : "none"}>
                <EventsOverview
                  timelineEvents={timelineEvents}
                  eventSummaries={eventSummaries}
                  riskLevels={riskLevels}
                />
              </Box>
              <Box flexGrow={1} display={selectedTab === 1 ? "block" : "none"}>
                <AssetVideoFeed
                  eventSummaries={eventSummaries}
                  timelineEvents={timelineEvents}
                  riskLevels={riskLevels}
                  asset={asset}
                  shouldLoadVideo={shouldLoadVideo}
                  isVideoPlaying={isVideoPlaying}
                  setVideoPlaying={setVideoPlaying}
                />
              </Box>
            </Grid>
          </Grid>
        </Grid>
      </SoftBox>
    </DashboardLayout>
  );
};

export default AssetOverview;
