import { createContext, useReducer, ReactNode, Dispatch } from "react";
import PropTypes from "prop-types";
import { User, Organization, Asset, JobsiteMetadata } from "data/models/models";

type DozerContext = {
  user: User | null;
  org: Organization | null;
  assets: Asset[] | null;
  jobsites: JobsiteMetadata | null;
};

type DozerContextAction =
  | { type: "saveUser"; payload: DozerContext["user"] }
  | { type: "logoutUser" }
  | { type: "saveOrganization"; payload: DozerContext["org"] }
  | { type: "saveAssets"; payload: DozerContext["assets"] }
  | { type: "saveJobsites"; payload: DozerContext["jobsites"] };

const initialState: DozerContext = {
  user: null,
  org: null,
  assets: null,
  jobsites: null,
};

export const DozerContext = createContext<DozerContext>(initialState);
export const DozerDispatchContext = createContext<Dispatch<DozerContextAction> | null>(null);

export const DozerProvider = ({ children }: { children: ReactNode }) => {
  const [dozerContext, dispatch] = useReducer(dozerUserReducer, initialState);

  return (
    <DozerContext.Provider value={dozerContext}>
      <DozerDispatchContext.Provider value={dispatch}>{children}</DozerDispatchContext.Provider>
    </DozerContext.Provider>
  );
};

DozerProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

function dozerUserReducer(dozerContext: DozerContext, action: DozerContextAction): DozerContext {
  switch (action.type) {
    case "saveUser": {
      return {
        ...dozerContext,
        user: action.payload,
      };
    }
    case "logoutUser": {
      return {
        user: null,
        org: null,
        assets: null,
        jobsites: null,
      };
    }
    case "saveOrganization": {
      return {
        ...dozerContext,
        org: action.payload,
      };
    }
    case "saveAssets": {
      return {
        ...dozerContext,
        assets: action.payload,
      };
    }
    case "saveJobsites": {
      return {
        ...dozerContext,
        jobsites: action.payload,
      };
    }
    default: {
      return {
        ...dozerContext,
      };
    }
  }
}

// export const TasksContext = createContext(null);
// export const TasksDispatchContext = createContext(null);

// export function TasksProvider({ children }) {
//     const [tasks, dispatch] = useReducer(
//         tasksReducer,
//         initialTasks
//     );

//     return (
//         <TasksContext.Provider value={tasks}>
//             <TasksDispatchContext.Provider value={dispatch}>
//                 {children}
//             </TasksDispatchContext.Provider>
//         </TasksContext.Provider>
//     );
// }

// function tasksReducer(tasks, action) {
//     switch (action.type) {
//         case 'added': {
//             return [...tasks, {
//                 id: action.id,
//                 text: action.text,
//                 done: false
//             }];
//         }
//         case 'changed': {
//             return tasks.map(t => {
//                 if (t.id === action.task.id) {
//                     return action.task;
//                 } else {
//                     return t;
//                 }
//             });
//         }
//         case 'deleted': {
//             return tasks.filter(t => t.id !== action.id);
//         }
//         default: {
//             throw Error('Unknown action: ' + action.type);
//         }
//     }
// }

// const initialTasks = [
//     { id: 0, text: 'Philosopher’s Path', done: true },
//     { id: 1, text: 'Visit the temple', done: false },
//     { id: 2, text: 'Drink matcha', done: false }
// ];
