import moment from "moment";
import PropTypes from "prop-types";
import { AssetEventLevel, AssetEvent } from "data/models/models";
import { Button, Chip } from "@mui/material";
import { CameraIcon } from "assets/images/icons/Icons";
import colors from "assets/theme/base/colors";

type IsLiveBadgeProps = {
  isLive: boolean;
};

const IsLiveBadge = ({ isLive }: IsLiveBadgeProps) => {
  return (
    // <span style={{ textTransform: "uppercase", background: isLive ? "orange" : "gray" }}>live</span>
    // <Chip label="primary" color="primary" />
    <Chip
      label="LIVE"
      variant={isLive ? "filled" : "outlined"}
      sx={{
        height: "auto",
        borderRadius: 1,
        fontWeight: "bold",
        "&.MuiChip-root": {
          border: "unset",
        },
        "&.MuiChip-outlined": {
          backgroundColor: colors.dozer.gray.dark,
        },
        "&.MuiChip-outlined span": {
          color: colors.dozer.gray.light,
        },
        "&.MuiChip-filled": {
          backgroundColor: isLive ? colors.live.main : colors.idle.main,
        },
        "&.MuiChip-filled span": {
          color: isLive ? colors.live.contrastText : colors.idle.contrastText,
        },
        span: {
          padding: "3px 6px 3px 6px",
        },
      }}
    />
  );
};

type VideoOverlayProps = {
  videoTimeStamp: Date | undefined;
  isLive: boolean;
  show: boolean;
  showGoLiveButton: boolean;
  goLiveOnClick: () => void;
  selectedAssetEvent: AssetEvent | undefined;
};

const VideoOverlay = ({
  videoTimeStamp,
  isLive,
  show,
  showGoLiveButton,
  goLiveOnClick,
  selectedAssetEvent,
}: VideoOverlayProps) => {
  const showIncidentOverlay =
    selectedAssetEvent &&
    (selectedAssetEvent.level === AssetEventLevel.Incident ||
      selectedAssetEvent.level === AssetEventLevel.Warning);
  return (
    <>
      <div
        style={{
          position: "absolute",
          top: 0,
          left: 0,
          right: 0,
          zIndex: 10,
          display: show ? "flex" : "hidden",
        }}
      >
        {selectedAssetEvent && showIncidentOverlay && (
          <IncidentOverlay assetEvent={selectedAssetEvent} />
        )}
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            padding: "18px",
            alignItems: "center",
            width: "100%",
          }}
        >
          <div>
            <IsLiveBadge isLive={isLive} />
            <span
              style={{
                fontWeight: "500",
                fontSize: "12pt",
                marginLeft: "1.5rem",
                color: "white",
                textTransform: "uppercase",
                textShadow: "0px 0px 3px rgb(0 0 0 / 50%)",
              }}
            >
              {moment(videoTimeStamp).format("MMM D, YYYY - hh:mm:ss A z")}
            </span>
          </div>
          {showGoLiveButton && (
            <Button
              component="label"
              variant="outlined"
              startIcon={<CameraIcon fill={colors.dozer.yellow} />}
              onClick={() => {
                if (goLiveOnClick) {
                  goLiveOnClick();
                }
              }}
            >
              GO LIVE
            </Button>
          )}
        </div>
      </div>
    </>
  );
};

type IncidentOverlayProps = {
  assetEvent: AssetEvent;
};

const IncidentOverlay = ({ assetEvent }: IncidentOverlayProps) => {
  const assetEventLevelBadgeColor = (level: AssetEventLevel) => {
    switch (level) {
      case AssetEventLevel.Incident:
        return "red";
      case AssetEventLevel.Warning:
        return "yellow";
      case AssetEventLevel.Normal:
        return "gray";
      default:
        return "green";
    }
  };

  if (assetEvent) {
    return (
      <>
        <div className="bg-gray-500 bg-opacity-75 relative py-3 px-6">
          <div className="grid justify-items-center">
            <span style={{ color: assetEventLevelBadgeColor(assetEvent.level) }}>
              {AssetEventLevel[assetEvent.level]}
            </span>
            <span className="uppercase font-bold text-white mt-3">{assetEvent.title}</span>
            <span className="uppercase text-gray-cool mt-3">
              {moment(assetEvent.eventTimestamp).format("MMM D, YYYY hh:mmA z")}
            </span>
          </div>
          <Button className="absolute top-1/3 right-6" color="primary" size="large">
            View Incident
          </Button>
        </div>
      </>
    );
  }
};

export default VideoOverlay;
