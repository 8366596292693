import React, { useState, useEffect } from "react";
import {
  DayPicker,
  DayContent,
  type DayContentProps,
  useActiveModifiers,
  type CaptionProps,
  Caption,
} from "react-day-picker";
import "react-day-picker/dist/style.css";
import { DozerMonthlyVideoInfo, AssetEventLevelType } from "data/models/models";
import Popper from "@mui/material/Popper";
import IconButton from "@mui/material/IconButton";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import CalendarIcon from "@mui/icons-material/CalendarMonth";
import moment from "moment";
import Grid from "@mui/material/Grid";
import { CircularProgress } from "@mui/material";
import colors from "assets/theme/base/colors";

type CalendarPickerProps = {
  selected: Date | undefined;
  setSelected: (date: Date | undefined) => void;
  onMonthChanged: (month: Date) => void;
  dateIndicators: DozerMonthlyVideoInfo[];
  loadingDateIndicators: boolean;
};

const dotStyle = {
  height: "3px",
  width: "3px",
  borderRadius: "50%",
  margin: "1px",
};

const getDates = (startDate: moment.MomentInput | any, stopDate: moment.MomentInput | any) => {
  var dateArray = [];
  var currentDate = startDate.valueOf();
  var stop = stopDate.valueOf();

  while (currentDate <= stop) {
    dateArray.push(moment(currentDate).format("L"));
    currentDate = moment(currentDate).add(1, "days").valueOf();
  }
  return dateArray;
};

export default function CalendarPicker({
  selected,
  setSelected,
  onMonthChanged,
  dateIndicators, // will return all the active days
  loadingDateIndicators,
}: CalendarPickerProps) {
  const [buttonAnchorEl, setButtonAnchorEl] = useState<HTMLButtonElement | null>(null);
  const [showCalendar, toggleShowCalendar] = useState<boolean>(false);
  const [disabledDays, setDisabledDays] = useState<Date[] | []>([]);
  const [normalDays, setNormalDays] = useState<Date[] | []>([]);
  const [warningDays, setWarningDays] = useState<Date[] | []>([]);
  const [incidentDays, setIncidentDays] = useState<Date[] | []>([]);
  const [month, setMonth] = useState<Date>(moment().toDate());

  useEffect(() => {
    // get 12 months of dates. Initially all disabled.
    const dates = getDates(moment().subtract(12, "months"), moment());

    const normalEvents: Date[] = [];
    const warningEvents: Date[] = [];
    const incidentEvents: Date[] = [];

    // remove from disabled days and apply the right event label
    dateIndicators.forEach((date) => {
      const { dayTimestamp, eventLevels } = date;
      // find index of date between
      const disabledDateIdx = dates.findIndex((d) => d === moment(dayTimestamp).format("L"));

      const modEventLevels = [...eventLevels];

      if (disabledDateIdx !== -1) {
        dates.splice(disabledDateIdx, 1);

        if (
          modEventLevels.includes(AssetEventLevelType.Normal) ||
          modEventLevels.includes(AssetEventLevelType.Unknown)
        ) {
          normalEvents.push(new Date(dayTimestamp));
        }
        if (modEventLevels.includes(AssetEventLevelType.Warning)) {
          warningEvents.push(new Date(dayTimestamp));
        }
        if (modEventLevels.includes(AssetEventLevelType.Incident)) {
          incidentEvents.push(new Date(dayTimestamp));
        }
      }
    });

    setDisabledDays(dates.map((date) => new Date(date)));
    setNormalDays(normalEvents);
    setWarningDays(warningEvents);
    setIncidentDays(incidentEvents);
  }, [dateIndicators]);

  useEffect(() => {
    if (selected) {
      setMonth(selected);
    }
  }, [selected]);

  // in order to close the popper by clicking out of it, we need to implement this.
  const handleClose = (event: Event | React.SyntheticEvent) => {
    if (buttonAnchorEl && buttonAnchorEl.contains(event.target as HTMLElement)) {
      return;
    }

    toggleShowCalendar(false);
  };

  const eventDayContent = ({ activeModifiers, date, displayMonth }: DayContentProps) => {
    const modifiers = useActiveModifiers(date);

    return (
      <Grid container direction="column" alignItems="center">
        <DayContent activeModifiers={activeModifiers} date={date} displayMonth={displayMonth} />
        <Grid container justifyContent="center">
          {!activeModifiers.disabled && modifiers.normal && (
            <div style={{ ...dotStyle, backgroundColor: "green" }} />
          )}
          {!activeModifiers.disabled && modifiers.warning && (
            <div style={{ ...dotStyle, backgroundColor: "#FDAC13" }} />
          )}
          {!activeModifiers.disabled && modifiers.incident && (
            <div style={{ ...dotStyle, backgroundColor: "red" }} />
          )}
        </Grid>
      </Grid>
    );
  };

  const loadingCaption = (props: CaptionProps) => {
    return (
      <Grid container direction="row" alignItems="center">
        <Caption {...props} />
        {loadingDateIndicators && (
          <CircularProgress size={20} sx={{ color: colors.dozer.yellow }} />
        )}
      </Grid>
    );
  };

  return (
    <>
      <IconButton
        onClick={(e) => {
          setButtonAnchorEl(e.currentTarget);
          toggleShowCalendar(!showCalendar);
        }}
      >
        <CalendarIcon />
      </IconButton>
      <Popper
        sx={{ zIndex: 1200 }}
        open={showCalendar}
        anchorEl={buttonAnchorEl}
        placement="top-end"
      >
        <ClickAwayListener onClickAway={handleClose}>
          <div
            style={{
              background: "white",
              padding: "5px",
              border: "1px solid black",
            }}
          >
            <DayPicker
              mode="single"
              selected={selected}
              onSelect={(date) => {
                if (date instanceof Date) {
                  setSelected(date);
                  toggleShowCalendar(false);
                }
              }}
              toDate={new Date(Date.now())}
              modifiers={{
                normal: normalDays,
                warning: warningDays,
                incident: incidentDays,
              }}
              month={month}
              onMonthChange={(month) => {
                // trigger the api call for the month
                onMonthChanged(month);
                setMonth(month);
              }}
              disabled={disabledDays}
              components={{
                DayContent: eventDayContent,
                Caption: loadingCaption,
              }}
              modifiersStyles={{
                selected: {
                  backgroundColor: colors.dozer.yellow,
                  // color: "orange",
                },
              }}
            />
          </div>
        </ClickAwayListener>
      </Popper>
    </>
  );
}
