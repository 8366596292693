/**
=========================================================
* Soft UI Dashboard PRO React - v4.0.1
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useMemo } from "react";

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";

// Soft UI Dashboard PRO React components
import SoftTypography from "components/SoftTypography";
import SoftBox from "components/SoftBox";

// Soft UI Dashboard PRO React example components
import DozerEquipmentTableCell from "./DozerEquipmentTableCell";
import React from "react";
import _ from "lodash";
import { Grid } from "@mui/material";

function DozerTable({ rows }) {
  return (
    <Grid container spacing={2} sx={{ width: '100%' }}>
      {rows}
    </Grid>
  );
}

DozerTable.defaultProps = {
  rows: [{}],
};

DozerTable.propTypes = {
  rows: PropTypes.arrayOf(PropTypes.node).isRequired
};

export default DozerTable;
