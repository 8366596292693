import { Auth0Provider } from "@auth0/auth0-react";
import React from "react";
import { useNavigate } from "react-router-dom";
import PropTypes from "prop-types";

export const Auth0ProviderWithNavigate = ({ children }) => {
  const navigate = useNavigate();

  const serverEndpoint = process.env.REACT_APP_BACKEND_BASE_URL;
  const auth0BaseUrl = process.env.REACT_APP_AUTH0_BASE_URL;
  const domain = process.env.REACT_APP_AUTH0_DOMAIN;
  const clientId = process.env.REACT_APP_AUTH0_CLIENT_ID;
  const audience = process.env.REACT_APP_AUTH0_AUDIENCE;
  const redirectUri = process.env.REACT_APP_AUTH0_CALLBACK_URL;

  const onRedirectCallback = (appState) => {
    navigate(appState?.returnTo || window.location.pathname);
  };

  // console.log("DOMAIN " + domain);
  // console.log("CLIENT_ID " + clientId);

  if (!(domain && clientId)) {
    return null;
  }

  return (
    <Auth0Provider
      domain={domain}
      clientId={clientId}
      authorizationParams={{
        redirect_uri: auth0BaseUrl,
        audience: audience,
      }}
      onRedirectCallback={onRedirectCallback}
    >
      {children}
    </Auth0Provider>
  );
};

Auth0ProviderWithNavigate.propTypes = {
  children: PropTypes.node.isRequired,
  domain: PropTypes.string,
  clientId: PropTypes.string,
  authorizationParams: PropTypes.object,
};
