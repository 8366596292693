/**
=========================================================
* Soft UI Dashboard PRO React - v4.0.1
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import { Auth0Provider, useAuth0 } from '@auth0/auth0-react';
import App from "./App";
import PropTypes from "prop-types";

// Soft UI Context Provider
import { SoftUIControllerProvider } from "./context";
import { Auth0ProviderWithNavigate } from "./components/auth0/Auth0ProviderWithNavigate";
import { DozerProvider } from "./context/dozer-context";

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  <BrowserRouter>
    <DozerProvider>
      <Auth0ProviderWithNavigate>
        <SoftUIControllerProvider>
          <App />
        </SoftUIControllerProvider>
      </Auth0ProviderWithNavigate>
    </DozerProvider>
  </BrowserRouter>
);
