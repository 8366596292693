import PropTypes from "prop-types";
import { useRef, useEffect, forwardRef } from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  TimeScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Bar, Chart } from "react-chartjs-2";
import annotationPlugin from "chartjs-plugin-annotation";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  TimeScale,
  annotationPlugin
);

const BarChart = (props) => {
  const { plugins, options, data, width, height } = props;

  return <Bar plugins={plugins} options={options} data={data} width={width} height={height} />;
};

export default BarChart;

BarChart.propTypes = {
  plugins: PropTypes.object.array,
  options: PropTypes.object.isRequired,
  data: PropTypes.object.isRequired,
  width: PropTypes.number.isRequired,
  height: PropTypes.number.isRequired,
  triggerAnnotation: PropTypes.func,
};
