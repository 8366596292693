import { useState, useEffect } from "react";
import SoftTypography from "components/SoftTypography";
import TimelineList from "examples/Timeline/TimelineList";
import TimelineItem from "examples/Timeline/TimelineItem";
import { Grid, Typography, Tabs, Tab } from "@mui/material";
import PropTypes from "prop-types";
import moment from "moment";
import {
  CheckCircleIconOutline,
  AlertCircleIconOutline,
  AlertDecagramIconOutline,
} from "assets/images/icons/Icons.tsx";
import LineChart from "examples/Charts/LineCharts/GradientLineChart";
import DozerTimelineItem from "examples/Timeline/DozerTimelineItem";
import colors from "assets/theme/base/colors";
import { eventTypeToTitle } from "data/api/util";

const RISK_RANGE = {
  DAY: "day",
  MONTH: "month",
  WEEK: "week",
};

const EVENT_LEVELS = {
  NORMAL: "NORMAL",
  WARNING: "WARNING",
  INCIDENT: "INCIDENT",
};

const ACTIVITY_RANGE = {
  DAY: "day",
  MONTH: "month",
  WEEK: "week",
};

const getEventColor = (level) => {
  switch (level) {
    case EVENT_LEVELS.NORMAL:
      return "success";
    case EVENT_LEVELS.WARNING:
      return "warning";
    case EVENT_LEVELS.INCIDENT:
      return "error";
    default:
      return "success";
  }
};

const getEventIcon = (level) => {
  switch (level) {
    case EVENT_LEVELS.NORMAL:
      return <CheckCircleIconOutline />;
    case EVENT_LEVELS.WARNING:
      return <AlertCircleIconOutline />;
    case EVENT_LEVELS.INCIDENT:
      return <AlertDecagramIconOutline />;
    default:
      return <CheckCircleIconOutline />;
  }
};

const getLineChart = (data) => ({
  datasets: data,
  labels: ["M", "T", "W", "Th", "F", "S", "Su"],
});

const tabsStyle = {
  width: 100,
  backgroundColor: "transparent !important",
  "& .MuiTabs-indicator": {
    bottom: "10%",
    height: "80%",
    borderRadius: 1,
    backgroundColor: colors.dozer.gray.dark,
    boxShadow: "none",
  },
  "& .Mui-selected": {
    color: `${colors.dozer.white} !important`,
  },
};

const EventsOverview = ({ timelineEvents, eventSummaries, riskLevels }) => {
  const [chartData, setChartData] = useState({});
  const [activityRange, setActivityRange] = useState(ACTIVITY_RANGE.DAY);
  const [riskRange, setRiskRange] = useState(RISK_RANGE.DAY);
  const [summaries, setSummaries] = useState({ warnings: 0, incidents: 0 });

  const setChartRange = () => {
    const currentChartData = riskLevels[riskRange];
    const chart = getLineChart(currentChartData);
    setChartData(chart);
  };

  useEffect(() => {
    if (riskLevels) {
      setChartRange();
    }
  }, [riskLevels]);

  useEffect(() => {
    if (riskLevels && riskRange) {
      setChartRange();
    }
  }, [riskRange]);

  useEffect(() => {
    const rangeSummaries = eventSummaries[activityRange];

    if (rangeSummaries) {
      setSummaries(rangeSummaries);
    }
  }, [activityRange]);

  if (timelineEvents?.length) {
    return (
      <Grid container sx={{ height: "100%" }} padding={0} columnSpacing={6}>
        <Grid container item xs={12} sm={6} padding={0} direction="column" sx={{ height: "100%" }}>
          <Typography
            variant="button"
            textTransform="uppercase"
            fontWeight="bold"
            color={colors.dozer.gray.light}
            marginBottom={1}
            sx={{ width: "100%" }}
          >
            Recent Activity
          </Typography>
          <Grid item sx={{ height: "500px", width: "100%" }}>
            <TimelineList
              cardSX={{ height: "100%", overflow: "auto", boxShadow: "none", paddingRight: 1 }}
            >
              {timelineEvents.map((event) => (
                <DozerTimelineItem
                  key={event.eventTimestamp}
                  color={getEventColor(event.level)}
                  icon={getEventIcon(event.level)}
                  title={event.title || event.type}
                  dateTime={moment(event.eventTimestamp).format("MM/DD/YYYY h:mm:ss A")}
                  // description={'Description'}
                  image={event.gifUrl}
                />
              ))}
            </TimelineList>
          </Grid>
        </Grid>

        <Grid container item xs={12} sm={6} direction="column" sx={{ height: "100%" }}>
          <Grid item container>
            <Typography
              variant="button"
              textTransform="uppercase"
              fontWeight="bold"
              color={colors.dozer.gray.light}
              marginBottom={1}
              sx={{ width: "100%" }}
            >
              Activity Summary
            </Typography>
            <Tabs
              value={activityRange}
              onChange={(e, value) => setActivityRange(value)}
              sx={tabsStyle}
            >
              <Tab label="D" value={ACTIVITY_RANGE.DAY} />
              <Tab label="W" value={ACTIVITY_RANGE.WEEK} />
              <Tab label="M" value={ACTIVITY_RANGE.MONTH} />
            </Tabs>
          </Grid>

          <Grid container justifyContent="space-around">
            <Grid item>
              <Typography sx={{ fontSize: "80px", textAlign: "center" }}>
                {summaries.warnings}
              </Typography>
              <Typography>WARNINGS</Typography>
            </Grid>
            <Grid item>
              <Typography sx={{ fontSize: "80px", textAlign: "center" }}>
                {summaries.incidents}
              </Typography>
              <Typography>INCIDENTS</Typography>
            </Grid>
          </Grid>

          <Typography
            variant="button"
            textTransform="uppercase"
            fontWeight="bold"
            color={colors.dozer.gray.light}
            marginBottom={1}
            sx={{ width: "100%" }}
          >
            Risk Level
          </Typography>
          <Tabs value={riskRange} onChange={(e, value) => setRiskRange(value)} sx={tabsStyle}>
            <Tab label="D" value={RISK_RANGE.DAY} />
            <Tab label="W" value={RISK_RANGE.WEEK} />
            <Tab label="M" value={RISK_RANGE.MONTH} />
          </Tabs>

          {/* <LineChart chart={chartData} height="100%" /> */}
        </Grid>
      </Grid>
    );
  }

  return null; // TODO: empty state
};

export default EventsOverview;

EventsOverview.defaultProps = {
  timelineEvents: [],
  eventSummaries: {},
  riskLevels: {},
};

EventsOverview.propTypes = {
  timelineEvents: PropTypes.arrayOf(PropTypes.object).isRequired,
  eventSummaries: PropTypes.object,
  riskLevels: PropTypes.object,
};
