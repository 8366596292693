import PropTypes from "prop-types";
import { Card } from "@mui/material";
import React from "react";

const DozerCard = ({ sx, children }) => {
  return <Card sx={{ ...sx }}>{children}</Card>;
};

export default DozerCard;

DozerCard.defaultProps = {
  children: null,
};

DozerCard.propTypes = {
  children: PropTypes.node.isRequired,
  sx: PropTypes.object,
};
