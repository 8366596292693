/**
=========================================================
* Soft UI Dashboard PRO React - v4.0.1
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import TableCell from "@mui/material/TableCell";

// Soft UI Dashboard PRO React components
import SoftTypography from "components/SoftTypography";
import SoftBox from "components/SoftBox";
import React from "react";
import { getPlaceholderAvatarUrl } from "../../../util/colors.ts";
import { Grid } from "@mui/material";

function DozerJobsiteTableCell({ jobsiteName, jobsiteCity, ...rest }) {
  let template;
  template = (
    <Grid item align="left" width="100%">
      <SoftBox display="flex" alignItems="center">
        <SoftBox component="img" src={getPlaceholderAvatarUrl(jobsiteName)} alt={jobsiteName} width="2.5rem" height="auto" />
        <SoftBox display="flex" width="100%" flexDirection="column" ml={2}>
          <SoftTypography variant="subtitle2" fontWeight="medium" textTransform="capitalize">
            {jobsiteName}
          </SoftTypography>
          <SoftTypography noWrap width="100%" variant="caption" fontWeight="medium" textTransform="capitalize"
            sx={{ textOverflow: 'ellipsis' }}>
            {jobsiteCity}
          </SoftTypography>
        </SoftBox>
      </SoftBox>
    </Grid>
  );

  return template;
}

// Setting default values for the props of SalesTableCell
DozerJobsiteTableCell.defaultProps = {
};

// Typechecking props for the SalesTableCell
DozerJobsiteTableCell.propTypes = {
  jobsiteName: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  jobsiteCity: PropTypes.string,
};

export default DozerJobsiteTableCell;