import colors from "assets/theme/base/colors";

// [background, color]
export const colorsAndBackgrounds = [
  ["#15803d", "#ffffff"],
  ["#DB7333", "#ffffff"],
  ["#60a5fa", "#ffffff"],
  ["#2563eb", "#ffffff"],
  ["#818cf8", "#ffffff"],
  ["#4f46e5", "#ffffff"],
  ["#fcd34d", "#ffffff"],
  ["#d97706", "#ffffff"],
  ["#f43f5e", "#ffffff"],
  ["#78350f", "#ffffff"],
];

export type ColorPair = {
  foreground: string;
  background: string;
};

export function getColorsForInitials(initials: string[]): ColorPair {
  if (initials[0] === "--") {
    return {
      background: colors.dozer.gray.medium,
      foreground: colorsAndBackgrounds[6][1],
    };
  }

  const idx = initials[0].charCodeAt(0) + (initials.length > 1 ? initials[1].charCodeAt(0) : 0);
  const chosen = colorsAndBackgrounds[idx % colorsAndBackgrounds.length];
  return {
    background: chosen[0],
    foreground: chosen[1],
  };
}

export function getInitials(name: string | null): string[] {
  if (!name) {
    return ["--"];
  }

  return name
    .split(" ")
    .map((s) => s.trim())
    .slice(0, 2)
    .map((s) => s[0]);
}

export function getPlaceholderAvatarUrl(name: string | null): string {
  const initials = getInitials(name);
  const colors = getColorsForInitials(initials);
  return `https://ui-avatars.com/api/?name=${encodeURIComponent(
    initials.join(" ")
  )}&background=${colors.background.substring(1)}&color=${colors.foreground.substring(1)}`;
}
