import DozerEquipmentTableCell from "examples/Tables/DozerTable/DozerEquipmentTableCell";
import { AssetTypeToImage } from "../../layouts/dashboards/default/data/assets";
import { chain } from "lodash";
import { capitalize } from "@mui/material";
import { AssetEventType, AssetEventTypeToEventLevel } from "data/models/models.ts";

export const getHeaders = (user) => {
  return {
    Authorization: `Bearer ${user.idToken}`,
  };
};

export const toUpperCase = (s) => {
  if (s === undefined) {
    return null;
  }
  return s.charAt(0).toUpperCase() + s.slice(1).toLowerCase();
};

export const mapJobSite = (jobsite) => {
  return {
    id: jobsite.groupId,
    address: jobsite.address ?? "",
    name: jobsite.name,
    imageUrl: null,
  };
};

export const equipmentCountOf = (assets, filter) => {
  return chain(assets)
    .filter(filter)
    .groupBy((a) => a.type)
    .map((object, typeName) => {
      return {
        count: object.length,
        type: {
          type: typeName,
          iconUrl: AssetTypeToImage[typeName],
        },
      };
    })
    .value();
};

export const eventTypeToTitle = (e) => {
  // Convert the AssetEventType enum to a string using the enum name.
  // For example, AssetEventType.BuildingProximity => 'Building Proximity'
  const enumName = AssetEventType[e];
  return enumName.split(/(?=[A-Z])/).join(" ");
};

export const mapToAssetEvent = (orgEvent, assets) => ({
  id: orgEvent.eventId,
  assetId: orgEvent.assetId,
  type: orgEvent.type,
  level: AssetEventTypeToEventLevel[orgEvent.type],
  level: orgEvent.level,
  jobsite: {
    id: orgEvent.jobsiteId,
    address: null,
    name: null,
    imageUrl: null,
  },
  eventTimestamp: orgEvent.eventTimestamp,
  eventEndTimestamp: orgEvent.eventEndTimestamp || null,
  gifUrl: orgEvent.gifUrl || null,
  clipMp4Url: orgEvent.clipMp4Url || null,
  webpThumbnailUrl: orgEvent.webpThumbnailUrl || null,
  streamTimestamp: orgEvent.streamTimestamp || null,
  thumbnailUrl: orgEvent.thumbnailUrl || null,
  title: eventTypeToTitle(orgEvent.type),
  title: orgEvent.description,
  subtitle: assets.hasOwnProperty(orgEvent.assetId)
    ? toUpperCase(assets[orgEvent.assetId].type)
    : null,
  location: null,
});

export const mapToAsset = (asset) => {
  const assetType = asset.type;

  return {
    assetId: asset.assetId,
    year: asset.year,
    isActive: asset.isActive,
    model: asset.model || null,
    location: null,
    snsTopicArn: asset.snsTopicArn || null,
    nickName: asset.nickname || null,
    videoStreamInfo: [],
    typeInfo: {
      type: assetType,
      iconUrl: AssetTypeToImage[assetType],
    },
  };
};

export const mapToActiveWindows = (activeWindow) => {
  return {
    type: activeWindow.type ?? null,
    startTimestamp: activeWindow.startTimestamp ?? null,
    stopTimestamp: activeWindow.stopTimestamp ?? null,
  };
};

export const mapEquipmentToRows = (equipment, isActive) => {
  return equipment.map((row) => {
    const equipmentCell = (
      <DozerEquipmentTableCell
        key={row.type.type}
        content={`${row.count} ${capitalize(row.type.type)}`}
        image={row.type.iconUrl}
        noBorder={true}
        isActive={isActive}
      />
    );

    return equipmentCell;
  });
};
