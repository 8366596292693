/**
=========================================================
* Soft UI Dashboard PRO React - v4.0.1
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useState, useEffect, useMemo, useContext } from "react";
import Dashboard from "views/Dashboard";

// react-router components
import { Routes, Route, Navigate, useLocation } from "react-router-dom";

// @mui material components
import { ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import Icon from "@mui/material/Icon";

// Soft UI Dashboard PRO React components
import SoftBox from "../src/components/SoftBox";

// Soft UI Dashboard PRO React example components
import Sidenav from "../src/examples/Sidenav";
import Configurator from "../src/examples/Configurator";

// Soft UI Dashboard PRO React themes
import theme from "../src/assets/theme";
import themeRTL from "../src/assets/theme/theme-rtl";

// RTL plugins
import rtlPlugin from "stylis-plugin-rtl";
import { CacheProvider } from "@emotion/react";
import createCache from "@emotion/cache";

// Soft UI Dashboard PRO React routes
import { ALL_ROUTES, MAIN_NAV_ROUTES } from "routes";

// Soft UI Dashboard PRO React contexts
import { useSoftUIController, setMiniSidenav, setOpenConfigurator } from "../src/context";

// Images
import brand from "assets/images/logo-ct.png";
import { useAuth0, withAuthenticationRequired } from "@auth0/auth0-react";
import { AuthenticationGuard } from "./components/auth0/AuthenticationGuard";
import { getUserByAuthId, signup } from "./data/api/user";
import { DozerContext, DozerDispatchContext } from "./context/dozer-context";

export default function App() {
  const [controller, dispatch] = useSoftUIController();
  const { miniSidenav, direction, layout, openConfigurator, sidenavColor } = controller;
  const [onMouseEnter, setOnMouseEnter] = useState(false);
  const [rtlCache, setRtlCache] = useState(null);
  const { pathname } = useLocation();
  const { isAuthenticated, isLoading, user, loginWithRedirect, logout, getAccessTokenSilently } =
    useAuth0();
  const dozerDispatch = useContext(DozerDispatchContext);
  const dozerContext = useContext(DozerContext);

  // Cache for the rtl
  useMemo(() => {
    const cacheRtl = createCache({
      key: "rtl",
      stylisPlugins: [rtlPlugin],
    });

    setRtlCache(cacheRtl);
  }, []);

  // Open sidenav when mouse enter on mini sidenav
  const handleOnMouseEnter = () => {
    if (miniSidenav && !onMouseEnter) {
      setMiniSidenav(dispatch, false);
      setOnMouseEnter(true);
    }
  };

  // Close sidenav when mouse leave mini sidenav
  const handleOnMouseLeave = () => {
    if (onMouseEnter) {
      setMiniSidenav(dispatch, true);
      setOnMouseEnter(false);
    }
  };

  // Change the openConfigurator state
  const handleConfiguratorOpen = () => setOpenConfigurator(dispatch, !openConfigurator);

  // Setting the dir attribute for the body element
  useEffect(() => {
    document.body.setAttribute("dir", direction);
  }, [direction]);

  // Setting page scroll to 0 when changing the route
  useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
  }, [pathname]);

  useEffect(() => {
    if (!isLoading && !isAuthenticated) {
      // we're not authenticated, redirect the user
      loginWithRedirect({ appState: { returnTo: window.location.pathname } });
      return;
    }

    if (!isLoading && isAuthenticated && user) {
      // we're authenticated
      // get the token
      const dozerLogin = async () => {
        const authToken = await getAccessTokenSilently();

        let dozerUser;
        const getDozerUser = await getUserByAuthId(user.sub, authToken);

        if (!getDozerUser) {
          // call signup
          const dozerSignupResult = await signup(
            {
              userAuthId: user.sub,
              email: user.email,
              firstName: user.given_name,
              lastName: user.family_name,
              pictureUrl: user.picture,
            },
            authToken
          );

          if (dozerSignupResult) {
            dozerUser = dozerSignupResult;
          } else {
            // log the user out and send em to www.dozer.ai
            logout({
              logoutParams: {
                returnTo: "http://wwww.dozer.ai",
              },
            });
            return null;
          }
        } else {
          dozerUser = getDozerUser;
        }

        // we're good...save the user
        dozerDispatch({
          type: "saveUser",
          payload: dozerUser,
        });

        sessionStorage.setItem("token", authToken);
      };

      dozerLogin();
    }
  }, [isAuthenticated, isLoading]);

  // console.log("APP.js - ", { isAuthenticated, isLoading });

  const getRoutes = (allRoutes) =>
    allRoutes.map((route) => {
      if (route.collapse) {
        // continue on to the next route
        return getRoutes(route.collapse);
      }

      if (route.route) {
        return <Route path={route.route} element={route.component} key={route.key} />;

        // return <Route path={route.route} element={<AuthenticationGuard component={route.component} />} key={route.key} />;
      }

      return null;
    });

  const configsButton = (
    <SoftBox
      display="flex"
      justifyContent="center"
      alignItems="center"
      width="3.5rem"
      height="3.5rem"
      bgColor="white"
      shadow="sm"
      borderRadius="50%"
      position="fixed"
      right="2rem"
      bottom="2rem"
      zIndex={99}
      color="dark"
      sx={{ cursor: "pointer" }}
      onClick={handleConfiguratorOpen}
    >
      <Icon fontSize="default" color="inherit">
        settings
      </Icon>
    </SoftBox>
  );

  if (!isAuthenticated || !dozerContext || !dozerContext.user) {
    return null;
  }

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      {layout === "dashboard" && (
        <>
          <Sidenav
            color={sidenavColor}
            brand={brand}
            brandName="Dozer"
            routes={MAIN_NAV_ROUTES}
            // onMouseEnter={handleOnMouseEnter}
            // onMouseLeave={handleOnMouseLeave}
          />
          {/* <Configurator /> */}
          {/* {configsButton} */}
        </>
      )}
      {/* {layout === "vr" && <Configurator />} */}
      <Routes>
        {getRoutes(ALL_ROUTES)}
        <Route path="/" element={<AuthenticationGuard component={<Dashboard />} />} />
      </Routes>
    </ThemeProvider>
  );
}
