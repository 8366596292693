import { useState, useEffect, useRef, lazy } from "react";
import moment from "moment-timezone";
import PropTypes from "prop-types";
import VideoOverlay from "components/VideoOverlay";
import VideoControls from "components/VideoControls/index";
import CalendarPicker from "components/CalendarPicker/index";
import { useParams } from "react-router-dom";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import VideoSlider from "components/VideoSlider";
import colors from "assets/theme/base/colors";
import _ from "lodash";
import { ASSET_POSITION } from "constants/assetPositions";
// import ZedVideoPlayers from "components/ZedVideoPlayers/index";
import {
  getAssetVideoHistory,
  getMonthlyVideoInfo,
  getAssetLiveVideoFeed,
  getAssetVideoFeed,
} from "data/api/asset";
import {
  Asset,
  AssetEvent,
  StreamInfo,
  DozerVideoSegment,
  DozerMonthlyVideoInfo,
  ArchivedVideoUrl,
  VideoUrl,
} from "data/models/models";

const VideoJS = lazy(() => import("components/VideoPlayer"));

// const LIVE_VIDEO_MOCK_RESPONSE = {
//   url: "https://b-54c7cb80.kinesisvideo.us-east-2.amazonaws.com/hls/v1/getHLSMasterPlaylist.m3u8?SessionToken=CiCrYQ_GGqVtlvxzMuSbcbf0wB6o9l-1OBug1Uaer_S13xIQFWxLfyrXZESTFBs1cKzvzxoZ800y_JA5QZ7Hgsw2MILd5gLJyioAAS38hiIga3Y-HoZWWZQxx24xOEha0l05wA6Aak9RzuLvx5ukbjA~",
//   expiration: 100,
//   videoType: "application/x-mpegURL",
//   isLive: true,
// };

export const VIDEO_ACTIONS = {
  SKIP_FORWARD: "skip_fw",
  SKIP_REWIND: "skip_rw",
};

type ComponentProps = {
  asset: Asset;
  setVideoPlaying: (isVideoPlaying: boolean) => {};
  isVideoPlaying: boolean;
  shouldLoadVideo: boolean;
};

type ZedDozerVideoSegment = {
  position: string | null;
  segments: DozerVideoSegment[] | [];
};

type VideoSegmentUrls = {
  [segmentStart: string]: ArchivedVideoUrl[];
};

// accounts for all zed cameras
type ZedVideoSegmentUrls = {
  [position: string]: VideoSegmentUrls;
};

const AssetVideoFeed = ({
  asset,
  shouldLoadVideo,
  isVideoPlaying,
  setVideoPlaying,
}: ComponentProps) => {
  const [streamArn, setStreamArn] = useState<string | null>(null); // Our main spherical camera arn
  const [zedStreamInfo, setZedStreamInfo] = useState<StreamInfo[]>([]); // Additional zed cameras
  const [isLive, setIsLive] = useState<boolean>(false);
  const [selectedDate, setSelectedDate] = useState<Date>(moment().startOf("day").toDate());
  const [videoSegments, setVideoSegments] = useState<DozerVideoSegment[]>([]);
  const [zedVideoSegments, setZedVideoSegments] = useState<ZedDozerVideoSegment[]>([]);
  const [dateIndicators, setDateIndicators] = useState<DozerMonthlyVideoInfo[]>([]);
  const [loadingDateIndicators, setLoadingDateIndicators] = useState<boolean>(true);
  const [liveVideoUrl, setLiveVideoUrl] = useState<VideoUrl | null>(null);
  const [videoStartTime, setVideoStartTime] = useState<number | -1>(0);
  const [videoControlsHidden, setVideoControlsHidden] = useState<boolean>(true);
  const [videoCurrentTime, setVideoCurrentTime] = useState<Date | undefined>(undefined);
  const [currentAssetEvent, setCurrentAssetEvent] = useState<AssetEvent | undefined>(undefined);

  // video controls state
  const [canGoNext, setCanGoNext] = useState<boolean>(true);
  const [canGoBack, setCanGoBack] = useState<boolean>(true);
  const [canSkipForward, setCanSkipForward] = useState<boolean>(true);
  const [canSkipBackward, setCanSkipBackward] = useState<boolean>(true);

  // holds the video segments for this selected date
  const videoSegmentsRef = useRef<DozerVideoSegment[]>([]);
  const zedVideoSegmentsRef = useRef<ZedDozerVideoSegment[] | []>([]);
  const videoSegmentUrls = useRef<VideoSegmentUrls>({});
  const zedVideoSegmentUrls = useRef<ZedVideoSegmentUrls>({});

  const videoUrl = useRef<ArchivedVideoUrl | VideoUrl | null>(null);
  const currentVideoSegmentIndex = useRef<number | undefined>(undefined);
  const activeVideoUrlIndex = useRef<number | undefined>(undefined);
  const videoCurrentTimeRef = useRef<Date | null>(null);
  const shouldPlayLiveVideo = useRef(true);

  const currentDay = moment(selectedDate).clone().utc().valueOf();
  const nextDay = moment(selectedDate).clone().add(1, "d").utc().valueOf();

  // const isChromium = !!window.chrome && (!!window.chrome.webstore || !!window.chrome.runtime)

  const params = useParams();
  const { assetId } = params;

  const videoJSOptions = {
    autoplay: true, // setting "muted" gets around google's no autoplay rule
    controls: false,
    responsive: true,
    fill: true,
    // preload: "auto",
    sources: videoUrl.current
      ? {
          src: videoUrl.current.url,
          type: videoUrl.current.videoType,
        }
      : undefined,
  };

  useEffect(() => {
    if (asset) {
      const assetStreamArns = asset?.videoStreamInfo;

      let mainCameraArn: string | null = null;
      let zedCamerasInfo: StreamInfo[] = [];

      assetStreamArns.forEach((arn) => {
        if (arn.position === ASSET_POSITION.SPHERICAL_CAM) {
          mainCameraArn = arn.kinesisStreamArn;
        } else {
          zedCamerasInfo.push(arn);
        }
      });

      setStreamArn(mainCameraArn);
      setZedStreamInfo(zedCamerasInfo);
    }
  }, [asset]);

  const fetchVideoSegments = async () => {
    let videoSegmentsResponse = null;

    if (!streamArn?.length || !assetId) {
      return;
    }

    try {
      videoSegmentsResponse = await getAssetVideoHistory(
        assetId,
        streamArn,
        currentDay, // on first load gets today's date
        nextDay
      );
    } catch (error) {
      console.log("Error fetching video segments", error);
    }

    if (videoSegmentsResponse?.length) {
      videoSegmentsRef.current = videoSegmentsResponse;
      setVideoSegments(videoSegmentsResponse);
    }
  };

  // fetches and compiles an object of videoSegmentResponses mapped to each zed camera
  const fetchZedVideoSegments = async () => {
    if (!zedStreamInfo?.length) {
      return;
    }
    const zedVideoSegmentResponses = [];

    // should fetch segments for all the zed cameras. Could we make this a single request?
    for (let i = 0; i < zedStreamInfo.length; i++) {
      const zedStreamArn = zedStreamInfo[i].kinesisStreamArn;
      const zedPosition = zedStreamInfo[i].position;

      let res = null;
      try {
        res = await getAssetVideoHistory(
          assetId,
          zedStreamArn,
          currentDay, // on first load gets today's date
          nextDay
        );
      } catch (error) {
        console.log(`Error fetching video segments for zed ${zedStreamArn}`, error);
      }

      if (res?.length) {
        zedVideoSegmentResponses.push({ position: zedPosition, segments: res });
      }
    }

    // console.log("zedVideoSegmentResponses", zedVideoSegmentResponses);
    zedVideoSegmentsRef.current = zedVideoSegmentResponses;
    setZedVideoSegments(zedVideoSegmentResponses);
  };

  const fetchLiveVideo = async () => {
    let liveVideoUrlResponse = null;

    try {
      liveVideoUrlResponse = await getAssetLiveVideoFeed(
        assetId,
        streamArn,
        moment(selectedDate).clone().utc().valueOf()
        // isChromium
      );
    } catch (error) {
      console.log("error in getAssetLiveVideoFeed", error);
    }

    if (liveVideoUrlResponse) {
      setLiveVideoUrl(liveVideoUrlResponse); // parse this.
      setIsLive(liveVideoUrlResponse.isLive);
      shouldPlayLiveVideo.current = liveVideoUrlResponse.isLive;
      setVideoCurrentTime(moment().toDate());
      videoCurrentTimeRef.current = moment().toDate();
    } else {
      setLiveVideoUrl(null);
    }
  };

  const fetchZedLiveVideo = () => {};

  const fetchDateIndicators = async (date: Date) => {
    let dateIndicatorsResponse;

    if (!streamArn || !date) {
      setLoadingDateIndicators(false);
      return;
    }

    const hasMonthDateAlready =
      _.findIndex(dateIndicators, function (o) {
        return moment(o.dayTimestamp).toDate().getMonth() == date.getMonth();
      }) > -1;
    if (hasMonthDateAlready) {
      return;
    }

    setLoadingDateIndicators(true);

    try {
      dateIndicatorsResponse = await getMonthlyVideoInfo(
        assetId,
        streamArn,
        moment(date).startOf("month").utc().valueOf(), // get 12 months of events from today.
        moment(date).endOf("month").utc().valueOf(), // end date,
        moment.tz.guess()
      );
    } catch (error) {
      console.log("fetchDateIndicators Error:", error);
    }

    if (dateIndicatorsResponse?.length) {
      const temp = _.unionBy(dateIndicators, dateIndicatorsResponse, "dayTimestamp");
      setDateIndicators(temp);
    }

    setLoadingDateIndicators(false);
  };

  useEffect(() => {
    if (streamArn?.length && asset) {
      fetchVideoSegments();
      fetchLiveVideo();
      fetchDateIndicators(moment().toDate());
    }
  }, [streamArn]);

  useEffect(() => {
    if (zedStreamInfo.length) {
      fetchZedVideoSegments();
      fetchZedLiveVideo();
    }
  }, [zedStreamInfo]);

  useEffect(() => {
    if (selectedDate) {
      setVideoCurrentTime(selectedDate);
      videoCurrentTimeRef.current = selectedDate;
      fetchVideoSegments();
    }
  }, [selectedDate]);

  useEffect(() => {
    if (liveVideoUrl && isLive) {
      updateVideoPlayer(moment().toDate().valueOf());
    }
  }, [liveVideoUrl]);

  useEffect(() => {
    if (videoSegments.length > 0) {
      updateCurrentVideoSegment(videoSegments[0]);
    }
  }, [videoSegments]);

  useEffect(() => {
    if (isLive) {
      const currentTime = moment().toDate();
      setVideoCurrentTime(currentTime);
      videoCurrentTimeRef.current = currentTime;
    }
  }, [isLive]);

  /**
   * @name fetchVideoStream - makes a user initiated fetch to Kinesis for video stream segment requested.
   * @param segment - required timeframe for the segment
   * @returns null or error. Updates videoSegmentUrls ref.
   */
  const fetchVideoStream = async (segment: DozerVideoSegment): Promise<void> => {
    if (segment === undefined) {
      return;
    }

    let currentStreamArn = streamArn;

    if (!currentStreamArn) {
      // a hack to get a non-stale state value in this callback function
      setStreamArn((prevStreamArn) => {
        currentStreamArn = prevStreamArn;
        return prevStreamArn; // don't actually change the state
      });
    }

    let result;

    if (currentStreamArn) {
      try {
        result = await getAssetVideoFeed(
          assetId,
          currentStreamArn,
          segment.start,
          segment.end
          // isChromium
        );
      } catch (error) {
        return Promise.reject(error);
      }

      if (result) {
        videoSegmentUrls.current[segment.start.toString()] = result;
      }
    }
  };

  const fetchZedVideoStreams = async (segment: DozerVideoSegment) => {
    if (segment === undefined) {
      return;
    }

    for (let i = 0; i < zedStreamInfo.length; i++) {
      const zedStreamArn = zedStreamInfo[i].kinesisStreamArn;

      try {
        const result = await getAssetVideoFeed(
          assetId,
          zedStreamArn,
          segment.start,
          segment.end
          // isChromium
        );

        if (result) {
          zedVideoSegmentUrls.current = {
            [zedStreamArn]: {
              [segment.start.toString()]: result,
            },
          };
          videoSegmentUrls.current[segment.start.toString()] = result;

          return result;
        }
      } catch (error) {
        return Promise.reject(error);
      }
    }
  };

  /**
   * @name verifyVideoSegmentUrlsPresent - ensures that video segment urls exist. If they don't, fetches stream again.
   * @param segment - the timeframe to check for
   * @returns - Promise.resolve() if urls exist, or fetches segment and updates videoSegmentUrls with new data.
   */
  const verifyVideoSegmentUrlsPresent = async (segment: DozerVideoSegment) => {
    if (segment === undefined) {
      throw "selection mark undefined, impossible!";
    }

    if (videoSegmentUrls.current.hasOwnProperty(segment?.start?.toString())) {
      return Promise.resolve();
    } else {
      try {
        await fetchVideoStream(segment);
      } catch (error) {
        return Promise.reject(error);
      }
    }
  };

  const updateCurrentVideoSegment = (selectedSegment: DozerVideoSegment) => {
    verifyVideoSegmentUrlsPresent(selectedSegment).then(() => {
      const urls = videoSegmentUrls.current[selectedSegment.start.toString()];

      for (let i = 0; i < urls.length; i++) {
        const url = urls[i];
        if (selectedSegment.start >= url.window.from && selectedSegment.start <= url.window.to) {
          updateVideoPlayer(url.window.from, selectedSegment);
          return;
        }
      }
      console.warn("Could not determine selected url.");
    });
  };

  /**
   * @name updateVideoPlayer - fires when there is a user-initiated change to the video player. I.e. skipping to a different time on slider.
   * @param selectedTime - newly selected time
   * @param selectedSegment - the video segment the current time is in.
   */
  const updateVideoPlayer = (selectedTime: number, selectedSegment?: DozerVideoSegment) => {
    // we're indicating that we're updating to live video...
    if (liveVideoUrl && shouldPlayLiveVideo.current) {
      videoUrl.current = liveVideoUrl;
      setVideoStartTime(-1);
      setVideoCurrentTime(moment().toDate());
      videoCurrentTimeRef.current = moment().toDate();
      return;
    }

    if (selectedSegment) {
      verifyVideoSegmentUrlsPresent(selectedSegment).then(() => {
        const urls = videoSegmentUrls.current[selectedSegment.start.toString()];

        let selectedUrlIndex = undefined;
        // finds the index of the url segment with the selected time.
        for (let i = 0; i < urls.length; i++) {
          const url = urls[i];

          if (selectedTime >= url.window.from && selectedTime <= url.window.to) {
            selectedUrlIndex = i;
            break;
          }
        }

        if (selectedUrlIndex === undefined) {
          return;
        }
        const selectedUrl = urls[selectedUrlIndex];

        if (selectedUrl !== videoUrl.current) {
          videoUrl.current = selectedUrl;
          setIsLive(liveVideoUrl === selectedUrl);
          shouldPlayLiveVideo.current = liveVideoUrl === selectedUrl;
        }

        setVideoStartTime((selectedTime - selectedUrl.window.from) / 1000);
        activeVideoUrlIndex.current = selectedUrlIndex;

        setVideoCurrentTime(moment(selectedTime).toDate());
        videoCurrentTimeRef.current = moment(selectedTime).toDate();

        for (let i = 0; i < videoSegmentsRef.current?.length; i++) {
          if (videoSegmentsRef.current?.[i].start === selectedSegment.start) {
            currentVideoSegmentIndex.current = i;
            const segmentEvents =
              videoSegmentsRef.current?.[currentVideoSegmentIndex.current]?.segmentEvents;
            if (segmentEvents && segmentEvents.length > 0) {
              setCurrentAssetEvent(
                videoSegmentsRef.current?.[currentVideoSegmentIndex.current].segmentEvents[0]
              );
            } else {
              setCurrentAssetEvent(undefined);
            }
            break;
          }
        }
      });
    }
  };

  const handleVideoEvent = (event: string, liveVideoUrl: VideoUrl | null) => {
    switch (event) {
      case "ended": {
        if (videoUrl.current?.url === liveVideoUrl?.url) {
          console.log("LIVE video ended, show UI");
        }
        break;
      }
      default:
        break;
    }
  };

  /**
   * @name handleTimeUpdated - fires on every second of the video playing.
   * @param {number} seconds - current seconds from start of video
   * @returns null
   */
  const handleTimeUpdated = (seconds: number) => {
    if (seconds === 0) {
      return;
    }

    // if we're playing live video, just set the current time to now
    if (shouldPlayLiveVideo.current === true) {
      const currentTime = moment().utc().toDate();
      setVideoCurrentTime(currentTime);
      videoCurrentTimeRef.current = currentTime;
      return;
    }

    // optimize this to update every second.
    if (currentVideoSegmentIndex.current === undefined) {
      return;
    }

    if (activeVideoUrlIndex.current === undefined) {
      return;
    }

    const currentSegment = videoSegmentsRef.current?.[currentVideoSegmentIndex.current];

    if (videoSegmentUrls.current.hasOwnProperty(currentSegment?.start?.toString())) {
      const urls = videoSegmentUrls.current[currentSegment.start.toString()];

      if (urls === undefined) {
        return;
      }

      const url = urls[activeVideoUrlIndex.current];

      const currentTime = moment(url.window.from).add(Math.round(seconds), "seconds").toDate();

      // check if the current time is at the end of the current URL window
      if (currentTime.valueOf() >= url.window.to) {
        /* 
        we're at the end of the url window, we need to do one of the following:
        1. move to the next url in this segment 
        2. move to the next segment
        3. stop playing video for the selected date because we're at the last second of video for the day
        */
        const hasNextUrl = urls.length - 1 > activeVideoUrlIndex.current;
        const hasNextSegment =
          videoSegmentsRef.current.length - 1 > currentVideoSegmentIndex.current;

        if (hasNextUrl) {
          // const nextUrl = _.first(urls, (url) => {
          //   url.window.from >= currentTime.valueOf();
          // });
          // updateCurrentVideoSegment(nextUrl);
        } else if (hasNextSegment) {
          const nextSegment = videoSegmentsRef.current?.[currentVideoSegmentIndex.current + 1];
          updateCurrentVideoSegment(nextSegment);
        }
      }

      setVideoCurrentTime(currentTime);
      videoCurrentTimeRef.current = currentTime;
    } else {
      // only call this once if videoSegmentUrls aren't set yet. Don't call on every time update.
      verifyVideoSegmentUrlsPresent(currentSegment);
    }
  };

  const handleOnVideoToggled = (toggleStatus: boolean) => {
    setVideoPlaying(toggleStatus);
  };

  const handlePreviousOnClick = () => {
    if (currentVideoSegmentIndex.current === undefined) {
      return;
    }

    if (activeVideoUrlIndex.current === undefined) {
      return;
    }

    const currentSegment = videoSegmentsRef.current?.[currentVideoSegmentIndex.current];

    if (videoSegmentUrls.current.hasOwnProperty(currentSegment?.start?.toString())) {
      const hasPreviousSegment = currentVideoSegmentIndex.current > 0;

      if (hasPreviousSegment) {
        console.log(`there is a previous segment, jumping...`);
        const prevSegment = videoSegmentsRef.current?.[currentVideoSegmentIndex.current - 1];
        updateCurrentVideoSegment(prevSegment);
      } else {
        console.log(`no previous url, no previous segment, we're at the beginning of the road`);
      }
    } else {
      // only call this once if videoSegmentUrls aren't set yet. Don't call on every time update.
      verifyVideoSegmentUrlsPresent(currentSegment);
    }
  };

  const handleSkipBackOnClick = () => {
    if (currentVideoSegmentIndex.current === undefined) {
      return;
    }

    if (activeVideoUrlIndex.current === undefined) {
      return;
    }

    if (!videoCurrentTime) {
      return;
    }

    const currentSegment = videoSegmentsRef.current?.[currentVideoSegmentIndex.current];
    const activeUrl =
      videoSegmentUrls.current[currentSegment.start.toString()][activeVideoUrlIndex.current];

    const newTime = Math.max(videoCurrentTime.valueOf() - 15000, activeUrl.window.from);
    updateVideoPlayer(newTime, currentSegment);
  };

  const handlePauseResumeOnClick = (shouldPlay: boolean) => {
    handleOnVideoToggled(shouldPlay);
  };

  const handleSkipForwardOnClick = () => {
    if (currentVideoSegmentIndex.current === undefined) {
      return;
    }

    if (activeVideoUrlIndex.current === undefined) {
      return;
    }

    if (!videoCurrentTime) {
      return;
    }
    const currentSegment = videoSegmentsRef.current?.[currentVideoSegmentIndex.current];
    const activeUrl =
      videoSegmentUrls.current[currentSegment.start.toString()][activeVideoUrlIndex.current];

    const newTime = Math.min(videoCurrentTime.valueOf() + 15000, activeUrl.window.to);
    updateVideoPlayer(newTime, currentSegment);
  };

  const handleNextOnClick = () => {
    if (currentVideoSegmentIndex.current === undefined) {
      return;
    }

    if (activeVideoUrlIndex.current === undefined) {
      return;
    }

    const currentSegment = videoSegmentsRef.current?.[currentVideoSegmentIndex.current];

    if (videoSegmentUrls.current.hasOwnProperty(currentSegment?.start?.toString())) {
      const hasNextSegment = videoSegmentsRef.current.length - 1 > currentVideoSegmentIndex.current;

      if (hasNextSegment) {
        const nextSegment = videoSegmentsRef.current?.[currentVideoSegmentIndex.current + 1];
        updateCurrentVideoSegment(nextSegment);
      }
    } else {
      // only call this once if videoSegmentUrls aren't set yet. Don't call on every time update.
      verifyVideoSegmentUrlsPresent(currentSegment);
    }
  };

  const onSliderSelection = async (selectionTime: number, segment: DozerVideoSegment) => {
    shouldPlayLiveVideo.current = false;

    if (!segment) {
      return;
    }

    if (selectionTime === undefined) {
      return;
    }

    if (videoSegmentUrls.current.hasOwnProperty(segment.start.toString())) {
      updateVideoPlayer(selectionTime, segment);
    } else {
      try {
        await fetchVideoStream(segment);

        updateVideoPlayer(selectionTime, segment);
      } catch (error) {
        console.log(error);
      }
    }
  };

  /**
   * This useEffect updates the video controls according to the video time
   * It will disable/enable skipping forward/backward depending on the segments
   */
  useEffect(() => {
    // get the current segment
    if (currentVideoSegmentIndex.current === undefined) {
      return;
    }

    if (activeVideoUrlIndex.current === undefined) {
      return;
    }

    if (!videoCurrentTime) {
      return;
    }

    const currentSegment = videoSegmentsRef.current?.[currentVideoSegmentIndex.current];

    if (videoSegmentUrls.current.hasOwnProperty(currentSegment?.start?.toString())) {
      const hasNextSegment = videoSegmentsRef.current.length - 1 > currentVideoSegmentIndex.current;
      const hasPreviousSegment =
        videoSegmentsRef.current.length > 1 && currentVideoSegmentIndex.current > 0;

      // check if the video segment urls can accomodate 15s forward
      const urls = videoSegmentUrls.current[currentSegment.start.toString()];
      const canSkipForward =
        _.findIndex(urls, (value) => {
          return value.window.to - videoCurrentTime.valueOf() >= 15_000;
        }) > -1;
      const canSkipBackward =
        _.findIndex(urls, (value) => {
          return videoCurrentTime.valueOf() - value.window.from >= 15_000;
        }) > -1;

      setCanGoNext(hasNextSegment);
      setCanGoBack(hasPreviousSegment);
      setCanSkipForward(canSkipForward);
      setCanSkipBackward(canSkipBackward);
    }
  }, [videoCurrentTime]);

  return (
    <div style={{ display: "flex", flexDirection: "column", height: "100%" }}>
      <div style={{ background: "black", height: "100%" }}>
        <div
          style={{ position: "relative", height: "100%" }}
          onMouseEnter={() => setVideoControlsHidden(false)}
          onMouseLeave={() => setVideoControlsHidden(true)}
        >
          <VideoOverlay
            videoTimeStamp={videoCurrentTime}
            isLive={isLive}
            showGoLiveButton={!isLive && liveVideoUrl !== null}
            show={isLive ? videoUrl !== undefined : true}
            selectedAssetEvent={currentAssetEvent}
            goLiveOnClick={() => {
              setIsLive(true);
              shouldPlayLiveVideo.current = true;
              updateVideoPlayer(moment().toDate().valueOf());
              setSelectedDate(moment().startOf("day").toDate());
              setVideoCurrentTime(moment().toDate());
            }}
          />
          {videoUrl.current && shouldLoadVideo && (
            <div
              // handle the click on the video to play/pause
              // onClick={() => {
              //   setVideoPlaying(!videoPlaying);
              // }}
              style={{ position: "relative", height: "100%", width: "100%" }}
            >
              <VideoJS
                options={videoJSOptions}
                onTimeUpdated={handleTimeUpdated}
                isVideoPlaying={isVideoPlaying}
                setVideoPlaying={setVideoPlaying}
                seekToTime={videoStartTime}
                onVideoEvent={(event) => {
                  handleVideoEvent(event, liveVideoUrl);
                }}
                isLive={isLive}
                // aspectRatio={'21:9'}
              />
            </div>
          )}

          {!isLive && !videoUrl.current && (
            <Grid
              container
              direction="column"
              justifyContent="center"
              alignItems="center"
              sx={{
                width: "100%",
                height: "100%",
              }}
            >
              <Typography
                variant="h5"
                sx={{
                  color: colors.dozer.gray,
                }}
              >
                Live Video Not Available
              </Typography>
              <Typography
                variant="caption"
                sx={{
                  color: colors.dozer.yellow,
                }}
              >
                Please Select Another Date Below
              </Typography>
            </Grid>
          )}

          {/* Don't show these buttons if live video is on. */}
          {!isLive && (
            <div
              style={{
                position: "relative",
                display: "flex",
                justifyContent: "center",
                bottom: "1rem",
              }}
            >
              <VideoControls
                hidden={videoControlsHidden}
                isPlaying={isVideoPlaying}
                canGoNext={canGoNext}
                canGoPrevious={canGoBack}
                canPlay={true}
                canSkipBack={canSkipBackward}
                canSkipForward={canSkipForward}
                previousOnClick={handlePreviousOnClick}
                skipBackOnClick={handleSkipBackOnClick}
                pauseResumeOnClick={handlePauseResumeOnClick}
                skipForwardOnCLick={handleSkipForwardOnClick}
                nextOnClick={handleNextOnClick}
              />
            </div>
          )}
        </div>
      </div>
      <Grid container display="flex" alignItems="center" padding={1}>
        <Grid
          container
          item
          display="flex"
          justifyContent="flex-start"
          alignItems="center"
          sm={1.2}
        >
          <CalendarPicker
            selected={selectedDate}
            setSelected={(date) => {
              if (date) {
                setSelectedDate(date);
                setIsLive(false);
                shouldPlayLiveVideo.current = false; // need to do this as well since Video Player reads memoized isLive value
              }
            }}
            dateIndicators={dateIndicators}
            loadingDateIndicators={loadingDateIndicators}
            onMonthChanged={(month) => {
              fetchDateIndicators(month);
            }}
          />
          <Grid item>
            <Typography variant="h6" sx={{ textTransform: "uppercase" }}>
              {moment(selectedDate).format("MMM DD")}
            </Typography>
          </Grid>
        </Grid>
        <Grid item sm>
          <VideoSlider
            selectedDate={selectedDate}
            segments={videoSegments}
            videoCurrentTime={videoCurrentTime}
            onSliderSelection={onSliderSelection}
          />
        </Grid>
        {/* <div className="flex flex-col pt-3 pl-4">
            <div className="flex flex-row space-x-4">
              <ViewListIcon
                className="w-8 h-8 inline hover:text-yellow-400 cursor-pointer"
                onClick={() => setShowHistoryPanelHandler(!showHistoryPanel)}
              />
            </div>
          </div> */}
      </Grid>
      {/* </div> */}
      {/* <div
        style={{
          display: showHistoryPanel ? "flex" : "hidden",
          position: "absolute",
          height: "32rem",
          width: "100%",
        }}
        className={
          (showHistoryPanel ? "" : "hidden") +
          " relative h-158 basis-1/4 w-full lg:w-72 px-4 py-4 sm:px-6 bg-gray overflow-x-auto no-scrollbar asset-video-activity-feed "
        }
      > */}
      {/* <div>
          <div className="flex flex-row-reverse">
            <button
              type="button"
              className="rounded-md bg-transparent text-white hover:text-yellow-400 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
              onClick={() => setShowHistoryPanelHandler(false)}
            >
              <span className="sr-only">Close panel</span>
              <XIcon className="h-6 w-6" aria-hidden="true" />
            </button>
          </div>
        </div> */}
      {/* <div className="mt-8">
          <ActivityFeed events={eventsToday} darkMode={true} />
        </div> */}
      {/* </div> */}

      {/* {zedStreamInfo?.length && (
        <ZedVideoPlayers
          streamInfo={zedStreamInfo}
          videoSegments={zedVideoSegments}
          videoJSOptions={videoJSOptions}
        />
      )} */}
    </div>
  );
};

export default AssetVideoFeed;

AssetVideoFeed.propTypes = {
  videoCurrentTime: PropTypes.string,
  liveVideoUrl: PropTypes.string,
  videoUrl: PropTypes.string,
  currentAssetEvent: PropTypes.object,
  navigateToLiveVideo: PropTypes.func,
  calendarDate: PropTypes.string,
  showHistoryPanel: PropTypes.bool,
  eventSummaries: PropTypes.object,
  timelineEvents: PropTypes.array,
  riskLevels: PropTypes.object,
  asset: PropTypes.object,
};
