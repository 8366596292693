import { useState, useEffect } from "react";
import SoftTypography from "components/SoftTypography";
import TimelineList from "examples/Timeline/TimelineList";
import TimelineItem from "examples/Timeline/TimelineItem";
import { Grid, Typography, Tabs, Tab, Box } from "@mui/material";
import PropTypes from "prop-types";
import moment from "moment";
import {
  CheckCircleIconOutline,
  AlertCircleIconOutline,
  AlertDecagramIconOutline,
} from "assets/images/icons/Icons.tsx";
import LineChart from "examples/Charts/LineCharts/GradientLineChart";
import DozerTimelineItem from "examples/Timeline/DozerTimelineItem";
import colors from "assets/theme/base/colors";
import DozerTable from "examples/Tables/DozerTable";
import { eventTypeToTitle, mapEquipmentToRows } from "data/api/util";

const RISK_RANGE = {
  DAY: "day",
  MONTH: "month",
  WEEK: "week",
};

const EVENT_LEVELS = {
  NORMAL: "NORMAL",
  WARNING: "WARNING",
  INCIDENT: "INCIDENT",
};

const ACTIVITY_RANGE = {
  DAY: "day",
  MONTH: "month",
  WEEK: "week",
};

const getEventColor = (level) => {
  switch (level) {
    case EVENT_LEVELS.NORMAL:
      return "success";
    case EVENT_LEVELS.WARNING:
      return "warning";
    case EVENT_LEVELS.INCIDENT:
      return "error";
    default:
      return "success";
  }
};

const getEventIcon = (level) => {
  switch (level) {
    case EVENT_LEVELS.NORMAL:
      return <CheckCircleIconOutline />;
    case EVENT_LEVELS.WARNING:
      return <AlertCircleIconOutline />;
    case EVENT_LEVELS.INCIDENT:
      return <AlertDecagramIconOutline />;
    default:
      return <CheckCircleIconOutline />;
  }
};

const getLineChart = (data) => ({
  datasets: data,
  labels: ["M", "T", "W", "Th", "F", "S", "Su"],
});

const getEventTitle = (title) => {
  if (title) {
    return title[0]?.toUpperCase() + title.slice(1, title.length)?.toLowerCase();
  } else {
    return undefined;
  }
};

const JobsiteOverview = ({ timelineEvents, eventSummaries, riskLevels, activeEquipment }) => {
  const [chartData, setChartData] = useState({});
  const [activityRange, setActivityRange] = useState(ACTIVITY_RANGE.DAY);
  const [riskRange, setRiskRange] = useState(RISK_RANGE.DAY);
  const [summaries, setSummaries] = useState({ warnings: 0, incidents: 0 });

  const setChartRange = () => {
    const currentChartData = riskLevels[riskRange];
    const chart = getLineChart(currentChartData);
    setChartData(chart);
  };

  useEffect(() => {
    if (riskLevels) {
      setChartRange();
    }
  }, [riskLevels]);

  useEffect(() => {
    if (riskLevels && riskRange) {
      setChartRange();
    }
  }, [riskRange]);

  useEffect(() => {
    const rangeSummaries = eventSummaries[activityRange];

    if (rangeSummaries) {
      setSummaries(rangeSummaries);
    }
  }, [activityRange]);

  if (timelineEvents?.length) {
    return (
      <Grid container sx={{ height: "100%" }} columnSpacing={6} paddingTop={3} paddingLeft={7}>
        <Grid container item xs={12} sm={6} direction="column" sx={{ height: "100%" }}>
          <Typography
            variant="button"
            textTransform="uppercase"
            fontWeight="bold"
            color={colors.dozer.gray.light}
            marginBottom={1}
            sx={{ width: "100%" }}
          >
            Current Active Equipment
          </Typography>
          <Box>
            <DozerTable rows={mapEquipmentToRows(activeEquipment, true)} />
          </Box>

          <Typography
            variant="button"
            textTransform="uppercase"
            fontWeight="bold"
            color={colors.dozer.gray.light}
            marginBottom={1}
            marginTop={4}
            sx={{ width: "100%" }}
          >
            Recent Activity
          </Typography>
          <Grid item sx={{ height: "350px" }}>
            <TimelineList
              cardSX={{ height: "100%", overflow: "auto", boxShadow: "none", paddingRight: 1 }}
            >
              {timelineEvents.map((event) => (
                <DozerTimelineItem
                  key={event.eventTimestamp}
                  color={getEventColor(event.level)}
                  icon={getEventIcon(event.level)}
                  title={eventTypeToTitle(event.type) || event.type}
                  dateTime={moment(event.eventTimestamp).format("dddd, MMMM Do YYYY, h:mm:ss a")}
                  // description={'Description'}
                  image={event.gifUrl}
                />
              ))}
            </TimelineList>
          </Grid>
        </Grid>

        <Grid container item xs={12} sm={6} direction="column" sx={{ height: "100%" }}>
          <Grid item>
            <Typography
              variant="button"
              textTransform="uppercase"
              fontWeight="bold"
              color={colors.dozer.gray.light}
              marginBottom={1}
              sx={{ width: "100%" }}
            >
              Activity Summary
            </Typography>
            <Tabs value={activityRange} onChange={(e, value) => setActivityRange(value)}>
              <Tab label="D" value={ACTIVITY_RANGE.DAY} />
              <Tab label="M" value={ACTIVITY_RANGE.MONTH} />
              <Tab label="M" value={ACTIVITY_RANGE.WEEK} />
            </Tabs>
          </Grid>

          <Grid container justifyContent="space-around">
            <Grid item>
              <Typography sx={{ fontSize: "80px", textAlign: "center" }}>
                {summaries.warnings}
              </Typography>
              <Typography>WARNINGS</Typography>
            </Grid>
            <Grid item>
              <Typography sx={{ fontSize: "80px", textAlign: "center" }}>
                {summaries.incidents}
              </Typography>
              <Typography>INCIDENTS</Typography>
            </Grid>
          </Grid>

          <Typography
            variant="button"
            textTransform="uppercase"
            fontWeight="bold"
            color={colors.dozer.gray.light}
            marginBottom={1}
            sx={{ width: "100%" }}
          >
            Risk Level
          </Typography>
          <Tabs value={riskRange} onChange={(e, value) => setRiskRange(value)}>
            <Tab label="D" value={RISK_RANGE.DAY} />
            <Tab label="M" value={RISK_RANGE.MONTH} />
            <Tab label="M" value={RISK_RANGE.WEEK} />
          </Tabs>

          {/* <LineChart chart={chartData} height="100%" /> */}
        </Grid>
      </Grid>
    );
  }

  return null; // TODO: empty state
};

export default JobsiteOverview;

JobsiteOverview.defaultProps = {
  activeEquipment: [],
  timelineEvents: [],
  eventSummaries: {},
  riskLevels: {},
};

JobsiteOverview.propTypes = {
  activeEquipment: PropTypes.arrayOf(PropTypes.object).isRequired,
  timelineEvents: PropTypes.arrayOf(PropTypes.object).isRequired,
  eventSummaries: PropTypes.object,
  riskLevels: PropTypes.object,
};
