export type IconProps = {
  fill?: string;
  className?: string;
};

export const CheckCircleIconOutline = ({ fill, className }: IconProps) => (
  <svg className={className} xmlns="http://www.w3.org/2000/svg" version="1.1" viewBox="0 0 24 24">
    <path
      fill={fill}
      d="M12 2C6.5 2 2 6.5 2 12S6.5 22 12 22 22 17.5 22 12 17.5 2 12 2M12 20C7.59 20 4 16.41 4 12S7.59 4 12 4 20 7.59 20 12 16.41 20 12 20M16.59 7.58L10 14.17L7.41 11.59L6 13L10 17L18 9L16.59 7.58Z"
    />
  </svg>
);

export const AlertCircleIconOutline = ({ fill, className }: IconProps) => {
  return (
    <svg className={className} xmlns="http://www.w3.org/2000/svg" version="1.1" viewBox="0 0 24 24">
      <path
        fill={fill}
        d="M11,15H13V17H11V15M11,7H13V13H11V7M12,2C6.47,2 2,6.5 2,12A10,10 0 0,0 12,22A10,10 0 0,0 22,12A10,10 0 0,0 12,2M12,20A8,8 0 0,1 4,12A8,8 0 0,1 12,4A8,8 0 0,1 20,12A8,8 0 0,1 12,20Z"
      />
    </svg>
  );
};

export const AlertDecagramIconOutline = ({ fill, className }: IconProps) => {
  return (
    <svg className={className} xmlns="http://www.w3.org/2000/svg" version="1.1" viewBox="0 0 24 24">
      <path
        fill={fill}
        d="M23,12L20.56,14.78L20.9,18.46L17.29,19.28L15.4,22.46L12,21L8.6,22.47L6.71,19.29L3.1,18.47L3.44,14.78L1,12L3.44,9.21L3.1,5.53L6.71,4.72L8.6,1.54L12,3L15.4,1.54L17.29,4.72L20.9,5.54L20.56,9.22L23,12M20.33,12L18.5,9.89L18.74,7.1L16,6.5L14.58,4.07L12,5.18L9.42,4.07L8,6.5L5.26,7.09L5.5,9.88L3.67,12L5.5,14.1L5.26,16.9L8,17.5L9.42,19.93L12,18.81L14.58,19.92L16,17.5L18.74,16.89L18.5,14.1L20.33,12M11,15H13V17H11V15M11,7H13V13H11V7"
      />
    </svg>
  );
};

export const AlertMinusCircleIcon = ({ fill, className }: IconProps) => {
  return (
    <svg className={className} xmlns="http://www.w3.org/2000/svg" version="1.1" viewBox="0 0 24 24">
      <path fill={fill} d="M0 0h24v24H0V0z" />
      <path d="M7 11v2h10v-2H7zm5-9C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 18c-4.41 0-8-3.59-8-8s3.59-8 8-8 8 3.59 8 8-3.59 8-8 8z" />
    </svg>
  );
};
export const MonitorDashboardIconOutline = ({ fill, className }: IconProps) => {
  return (
    <svg
      className={className}
      xmlns="http://www.w3.org/2000/svg"
      version="1.1"
      width="24"
      height="24"
      viewBox="0 0 24 24"
    >
      <path
        fill={fill}
        d="M21,16V4H3V16H21M21,2A2,2 0 0,1 23,4V16A2,2 0 0,1 21,18H14V20H16V22H8V20H10V18H3C1.89,18 1,17.1 1,16V4C1,2.89 1.89,2 3,2H21M5,6H14V11H5V6M15,6H19V8H15V6M19,9V14H15V9H19M5,12H9V14H5V12M10,12H14V14H10V12Z"
      />
    </svg>
  );
};

export const HomeCityIconOutline = ({ fill, className }: IconProps) => {
  return (
    <svg
      className={className}
      xmlns="http://www.w3.org/2000/svg"
      version="1.1"
      width="24"
      height="24"
      viewBox="0 0 24 24"
    >
      <path
        fill={fill}
        d="M0,21V10L7.5,5L15,10V21H10V14H5V21H0M24,2V21H17V8.93L16,8.27V6H14V6.93L10,4.27V2H24M21,14H19V16H21V14M21,10H19V12H21V10M21,6H19V8H21V6Z"
      />
    </svg>
  );
};

export const ExcavatorIconOutline = ({ fill, className }: IconProps) => {
  return (
    <svg
      className={className}
      xmlns="http://www.w3.org/2000/svg"
      version="1.1"
      width="24"
      height="24"
      viewBox="0 0 24 24"
    >
      <path
        fill={fill}
        d="M18.5 18.5C19.04 18.5 19.5 18.96 19.5 19.5S19.04 20.5 18.5 20.5H6.5C5.96 20.5 5.5 20.04 5.5 19.5S5.96 18.5 6.5 18.5H18.5M18.5 17H6.5C5.13 17 4 18.13 4 19.5S5.13 22 6.5 22H18.5C19.88 22 21 20.88 21 19.5S19.88 17 18.5 17M21 11H18V7H13L10 11V16H22L21 11M11.54 11L13.5 8.5H16V11H11.54M9.76 3.41L4.76 2L2 11.83C1.66 13.11 2.41 14.44 3.7 14.8L4.86 15.12L8.15 12.29L4.27 11.21L6.15 4.46L8.94 5.24C9.5 5.53 10.71 6.34 11.47 7.37L12.5 6H12.94C11.68 4.41 9.85 3.46 9.76 3.41Z"
      />
    </svg>
  );
};

export const CameraIcon = ({ fill, className }: IconProps) => {
  return (
    <svg
      className={className}
      xmlns="http://www.w3.org/2000/svg"
      version="1.1"
      width="24"
      height="24"
      viewBox="0 0 24 24"
    >
      <path
        fill={fill}
        d="M18,16L14,12.8V16H6V8H14V11.2L18,8M20,4H4A2,2 0 0,0 2,6V18A2,2 0 0,0 4,20H20A2,2 0 0,0 22,18V6C22,4.89 21.1,4 20,4Z"
      />
    </svg>
  );
};

export const VideoPreviousIcon = ({ fill, className }: IconProps) => {
  return (
    <svg
      className={className}
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
    >
      <path
        id="skip-previous"
        d="M6,20.838V6H8.473V20.838H6m4.328-7.419L20.838,6V20.838Z"
        fill={fill}
      />
    </svg>
  );
};

export const VideoNextIcon = ({ fill, className }: IconProps) => {
  return (
    <svg
      className={className}
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
    >
      <path
        id="skip-next"
        d="M18.365,20.838h2.473V6H18.365M6,20.838l10.51-7.419L6,6Z"
        fill={fill}
      />
    </svg>
  );
};

export const VideoSkipBackIcon = ({ fill, className }: IconProps) => {
  return (
    <svg
      className={className}
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
    >
      <path
        id="rewind-15"
        d="M14.747,3a13,13,0,0,1,12.328,8.928l-2.931.964A9.856,9.856,0,0,0,8.416,8.416l3.24,3.24H3V3L6.215,6.215A13,13,0,0,1,14.747,3M11.656,14.129V26.494H9.183V16.6H6.71V14.129h4.946m2.473,0h7.419V16.6H16.6v2.473h2.473a2.472,2.472,0,0,1,2.473,2.473v2.473a2.464,2.464,0,0,1-2.473,2.473H14.129V24.021h4.946V21.548H14.129Z"
        transform="translate(-1.53 -3)"
        fill={fill}
      />
    </svg>
  );
};

export const VideoSkipForwardIcon = ({ fill, className }: IconProps) => {
  return (
    <svg
      className={className}
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
    >
      <path
        id="fast-forward-15"
        d="M13.858,3A13,13,0,0,1,22.39,6.215L25.6,3v8.656H16.949l3.24-3.24A9.856,9.856,0,0,0,4.461,12.892L1.53,11.928A13,13,0,0,1,13.858,3M12,14.129V26.494H9.53V16.6H7.057V14.129H12m2.473,0H21.9V16.6H16.949v2.473h2.473A2.472,2.472,0,0,1,21.9,21.548v2.473a2.464,2.464,0,0,1-2.473,2.473H14.476V24.021h4.946V21.548H14.476Z"
        transform="translate(-1.53 -3)"
        fill={fill}
      />
    </svg>
  );
};

export const VideoPlayIcon = ({ fill, className }: IconProps) => {
  return (
    <svg
      className={className}
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
    >
      <path id="play" d="M8,5.14V22.451L21.6,13.8Z" fill={fill} />
    </svg>
  );
};

export const VideoPauseIcon = ({ fill, className }: IconProps) => {
  return (
    <svg
      className={className}
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 320 512"
    >
      <path
        d="M272 63.1l-32 0c-26.51 0-48 21.49-48 47.1v288c0 26.51 21.49 48 48 48L272 448c26.51 0 48-21.49 48-48v-288C320 85.49 298.5 63.1 272 63.1zM80 63.1l-32 0c-26.51 0-48 21.49-48 48v288C0 426.5 21.49 448 48 448l32 0c26.51 0 48-21.49 48-48v-288C128 85.49 106.5 63.1 80 63.1z"
        fill={fill}
      />
    </svg>
  );
};
