import { useState, useEffect, useContext } from "react";
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import { Box, Button, Card, Chip, Grid, Stack, Typography } from "@mui/material";
import DozerCard from "examples/Cards/DozerCard";
import SoftAvatar from "components/SoftAvatar";
import { NavLink } from "react-router-dom";

import { DozerContext, DozerDispatchContext } from "context/dozer-context";

import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import colors from "assets/theme/base/colors";
import typography from "../../assets/theme/base/typography";
import { getJobsiteAssets, getJobsites } from "../../data/api/jobsites";
import { getPlaceholderAvatarUrl } from "../../util/colors.ts";
import _ from "lodash";

const Jobsites = () => {
  const dozerDispatch = useContext(DozerDispatchContext);
  const dozerContext = useContext(DozerContext);
  const { user: dozerUser } = dozerContext;

  const [jobsites, setJobsites] = useState(null);

  // call API for jobsites.
  const fetchJobsites = async () => {
    const { organizationId } = dozerUser;

    if (organizationId) {
      let jobsitesResponse = null;
      try {
        jobsitesResponse = await getJobsites(organizationId);
      } catch (error) {
        console.log("error fetching jobsites", error);
      }

      if (jobsitesResponse?.jobesiteSummaries?.length) {
        dozerDispatch({
          type: "saveJobsites",
          payload: jobsitesResponse.jobesiteSummaries,
        });
        setJobsites(jobsitesResponse.jobesiteSummaries);
      }
    }
  };

  const mapEquipmentToCount = (equipment) => {
    return _.countBy(equipment, "typeInfo.iconUrl");
  };

  useEffect(() => {
    if (dozerUser) {
      // fetchJobsites();

      const temp = async () => {
        const { organizationId } = dozerUser;

        if (organizationId) {
          let jobsitesResponse = null;
          try {
            jobsitesResponse = await getJobsites(organizationId);
          } catch (error) {
            console.log("error fetching jobsites", error);
          }

          if (jobsitesResponse?.jobesiteSummaries?.length) {
            let allJobsitesWithAssets = await Promise.all(
              _.map(jobsitesResponse?.jobesiteSummaries, async (jobsite) => {
                let jobsiteAssetsResponse = null;

                try {
                  jobsiteAssetsResponse = await getJobsiteAssets(jobsite.metadata.id);
                } catch (error) {
                  console.log(`Error fetching assets for ${jobsite.metadata.id}`, error);
                }

                if (jobsiteAssetsResponse) {
                  let assetCount = mapEquipmentToCount(jobsiteAssetsResponse.assets);
                  jobsite.assets = assetCount;
                }

                return jobsite;
              })
            );

            dozerDispatch({
              type: "saveJobsites",
              payload: allJobsitesWithAssets,
            });
            setJobsites(allJobsitesWithAssets);
          }
        }
      };

      temp();
    }
  }, []);

  console.log("jobsites", jobsites);

  return (
    <DashboardLayout pageTitle="Jobsites">
      <SoftBox py={3} sx={{ height: "100%" }}>
        <Grid container sx={{ height: "100%" }}>
          <Grid item xs={12} lg={12}>
            <SoftBox mb={3} p={1} sx={{ height: "auto" }}>
              <Grid container sx={{ height: "auto" }} spacing={2}>
                {jobsites?.map((jobsite) => (
                  <Grid item xs={3} key={jobsite.metadata?.id}>
                    <NavLink to={`/jobsites/${jobsite.metadata?.id}`}>
                      <Card
                        sx={{
                          overflow: "visible",
                          minHeight: "250px",
                        }}
                      >
                        <Grid
                          container
                          direction="column"
                          justifyContent="center"
                          alignItems="space-between"
                        >
                          <Box
                            sx={{
                              backgroundColor: colors.dozer.white,
                              display: "flex",
                              flexDirection: "column",
                              alignItems: "center",
                            }}
                          >
                            <SoftBox
                              component="img"
                              src={getPlaceholderAvatarUrl(jobsite.metadata?.name)}
                              alt={jobsite.metadata?.name}
                              width="2.5rem"
                              height="auto"
                              sx={{ margin: "auto", marginTop: "-1.25rem", marginBottom: 1 }}
                            />

                            <Typography
                              variant="h4"
                              fontWeight="medium"
                              color={colors.dozer.gray.dark}
                              marginBottom={1}
                              xs={12}
                              textAlign="center"
                            >
                              {jobsite.metadata?.name}
                            </Typography>

                            <Chip
                              label={jobsite.status ? "ACTIVE" : "IDLE"}
                              color={jobsite.status ? "active" : "idle"}
                              sx={{
                                "& .MuiChip-label": {
                                  fontWeight: typography.fontWeightMedium,
                                },
                                mb: -2,
                              }}
                            />
                          </Box>

                          <Box
                            sx={{
                              display: "flex",
                              flexDirection: "row",
                              alignItems: "flex-start",
                              justifyContent: "space-evenly",
                              marginTop: 4,
                            }}
                          >
                            <Box
                              sx={{
                                display: "flex",
                                flexDirection: "column",
                                alignItems: "flex-start",
                              }}
                            >
                              <Typography
                                variant="button"
                                textTransform="uppercase"
                                fontWeight="bold"
                                color={colors.dozer.gray.light}
                                sx={{ width: "100%" }}
                              >
                                Active Equipment
                              </Typography>

                              <Typography
                                variant="h2"
                                color={
                                  jobsite.activeEquipment.length > 1
                                    ? colors.active.main
                                    : colors.dozer.gray.dark
                                }
                                opacity={0.7}
                                fontWeight="regular"
                                lineHeight={1}
                                mb={1}
                              >
                                {jobsite.activeEquipment.length}
                              </Typography>

                              <Typography
                                variant="button"
                                textTransform="uppercase"
                                fontWeight="bold"
                                color={colors.dozer.gray.light}
                                sx={{ width: "100%" }}
                              >
                                incidents today
                              </Typography>

                              <Typography
                                variant="h2"
                                color={colors.dozer.gray.dark}
                                opacity={0.7}
                                fontWeight="regular"
                                lineHeight={1}
                              >
                                {jobsite.incidentsToday}
                              </Typography>
                            </Box>

                            <Stack spacing={1}>
                              {_.map(jobsite.assets, (value, key) => (
                                <Box
                                  key={key}
                                  sx={{
                                    display: "flex",
                                    flexDirection: "row",
                                    alignItems: "center",
                                  }}
                                >
                                  <Box
                                    component="img"
                                    mr={2}
                                    src={key}
                                    alt={key}
                                    width="auto"
                                    height={"2rem"}
                                  />

                                  <Typography
                                    variant="subtitle2"
                                    color={colors.dozer.gray.dark}
                                    opacity={0.7}
                                    fontWeight="bold"
                                    lineHeight={1}
                                  >
                                    {value}
                                  </Typography>
                                </Box>
                              ))}
                            </Stack>
                          </Box>
                        </Grid>
                      </Card>
                    </NavLink>
                  </Grid>
                ))}
              </Grid>
            </SoftBox>
          </Grid>
        </Grid>
      </SoftBox>
    </DashboardLayout>
  );
};

export default Jobsites;
