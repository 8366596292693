import { useState, useEffect, useContext } from "react";
import SoftBox from "components/SoftBox";
import { Grid, Tabs, Tab, Box, Typography, Avatar } from "@mui/material";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import EventsOverview from "components/EventsOverview";
import AssetVideoFeed from "components/AssetVideoFeed";

import { getAssetOverview } from "data/api/asset";
import { useParams, NavLink } from "react-router-dom";
import colors from "../../assets/theme/base/colors";
import { DozerContext } from "../../context/dozer-context";
import _ from "lodash";
import {
  getJobsiteActivity,
  getJobsiteAssets,
  getJobsiteOverview,
} from "../../data/api/jobsites.js";
import { getColorsForInitials, getInitials, getPlaceholderAvatarUrl } from "../../util/colors.ts";
import JobsiteOverview from "components/JobsiteOverview";
import JobsiteAssets from "components/JobsiteAssets";

const JobsiteDetails = () => {
  const dozerContext = useContext(DozerContext);
  const { jobsites: dozerJobsites } = dozerContext;
  const [jobsite, setJobsite] = useState(null);
  const [selectedTab, setSelectedTab] = useState(0);
  const [activeEquipment, setActiveEquipment] = useState([]);
  const [jobsiteAssets, setJobsiteAssets] = useState([]);
  const [jobsiteEvents, setJobsiteEvents] = useState(null);
  const [eventSummaries, setEventSummaries] = useState({ day: null, month: null, week: null });
  const [timelineEvents, setTimelineEvents] = useState([]);
  const [riskLevels, setRiskLevels] = useState({ day: [], month: [], week: [] });

  const params = useParams();
  const { jobsiteId } = params;

  const fetchJobsite = async () => {
    let jobsiteResponse = null;

    try {
      jobsiteResponse = await getJobsiteOverview(jobsiteId);
    } catch (error) {
      console.log("Error fetching jobsite overview", error);
    }

    if (jobsiteResponse) {
      const {
        jobsite: jobsite,
        activeEquipment: activeEquipment,
        eventsSummaries: summaries,
        recentEvents: events,
        riskLevels: levels,
      } = jobsiteResponse;
      if (summaries?.length) {
        setEventSummaries(summaries);
      }
      if (events?.length) {
        setTimelineEvents(events);
      }
      if (levels?.length) {
        setRiskLevels(levels);
      }
      if (jobsite) {
        setJobsite(jobsite);
      }

      if (activeEquipment) {
        setActiveEquipment(activeEquipment);
      }

      console.log("jobsite", jobsite);
      console.log("timelineveents", timelineEvents);
      console.log("event summaries", eventSummaries);
      console.log("risk levels", riskLevels);
      console.log("active equipment", activeEquipment);
    }
  };

  const fetchJobsiteEvents = async () => {
    let jobsiteEventsResponse = null;

    try {
      jobsiteEventsResponse = await getJobsiteActivity(jobsiteId);
    } catch (error) {
      console.log("Error fetching jobsiteEvents", error);
    }

    if (jobsiteEventsResponse) {
      setJobsiteEvents(jobsiteEventsResponse);
    }
  };

  const fetchJobsiteAssets = async () => {
    let jobsiteAssetsResponse = null;

    try {
      jobsiteAssetsResponse = await getJobsiteAssets(jobsiteId);
    } catch (error) {
      console.log("Error fetching jobsiteEvents", error);
    }

    if (jobsiteAssetsResponse) {
      setJobsiteAssets(jobsiteAssetsResponse);
    }
  };

  useEffect(() => {
    if (jobsiteId) {
      fetchJobsite();
      fetchJobsiteEvents();
      fetchJobsiteAssets();
    }
  }, []);

  const handleSelectTab = (e, value) => {
    setSelectedTab(value);
  };

  function getJobsiteAvatar(jobsiteName) {
    const initials = getInitials(jobsiteName);
    const colors = getColorsForInitials(initials);
    return (
      <Avatar
        width="2.5rem"
        height="auto"
        sx={{ ml: 1, bgcolor: colors.background, color: colors.foreground }}
        variant="square"
      >
        {initials}
      </Avatar>
    );
  }

  return (
    <DashboardLayout pageTitle="Jobsites">
      <SoftBox pt={3} bgColor="white">
        <Grid container direction="column" sx={{ width: "100%", height: "100%" }}>
          <Grid container sx={{ height: "100%" }}>
            <Grid container item xs={12} alignItems="center" sx={{ paddingLeft: "15px" }}>
              <NavLink style={{ display: "flex" }} to={`/jobsites`}>
                <ArrowBackIcon sx={{ fill: colors.dozer.gray.light }} fontSize="large" />
              </NavLink>
              {getJobsiteAvatar(jobsite && jobsite.name)}
              <Typography
                variant="h4"
                fontWeight="light"
                color={colors.dozer.gray.dark}
                ml={1.5}
                xs={12}
              >
                {jobsite && jobsite.name}
              </Typography>
              <Typography
                variant="caption"
                textTransform="uppercase"
                fontWeight="light"
                color={colors.dozer.gray.medium}
                ml={1.5}
              >
                {jobsite && jobsite.address}
              </Typography>
            </Grid>
            <Grid item xs={12} sx={{ height: "600px" }}>
              <Tabs
                value={selectedTab}
                onChange={handleSelectTab}
                sx={{
                  mt: 1,
                  ml: 6,
                  width: "fit-content",
                  backgroundColor: "transparent !important",
                  "& .MuiTabs-indicator": {
                    display: "none",
                  },
                }}
              >
                <Tab label="Overview" value={0} sx={{ mr: 3 }} />
                <Tab label="Assets" value={1} />
              </Tabs>
              <Box display={selectedTab === 0 ? "block" : "none"}>
                <JobsiteOverview
                  activeEquipment={activeEquipment}
                  timelineEvents={timelineEvents}
                  eventSummaries={eventSummaries}
                  riskLevels={riskLevels}
                />
              </Box>
              <Box display={selectedTab === 1 ? "block" : "none"}>
                <JobsiteAssets equipment={jobsiteAssets.assets} />
              </Box>
            </Grid>
          </Grid>
        </Grid>
      </SoftBox>
    </DashboardLayout>
  );
};

export default JobsiteDetails;
