// import ALERT_WARNING from 'assets/images/icons/svg/alert-circle-outline.svg';
// import ALERT_INCIDENT from 'assets/images/icons/svg/alert-decagram-outline.svg';
// import ALERT_NORMAL from 'assets/images/icons/svg/minus-circle.svg';
// import ALERT_EQUIPMENT_START_STOP from 'assets/images/icons/svg/check-circle-outline.svg';
import colors from "assets/theme/base/colors";
import {
  AlertCircleIconOutline,
  AlertDecagramIconOutline,
  AlertMinusCircleIcon,
} from "./Icons.tsx";

export const EventLevelToImage = {
  WARNING: <AlertCircleIconOutline fill={colors.warning.main} />,
  NEAR_MISS: <AlertCircleIconOutline fill={colors.nearMiss.main} />,
  INCIDENT: <AlertDecagramIconOutline fill={colors.error.main} />,
  NORMAL: <AlertMinusCircleIcon fill={colors.text.main} />,
  UNKNOWN: <AlertMinusCircleIcon fill={colors.text.main} />,
};

export const EventTypeToImage = {
  STARTED: <AlertCircleIconOutline fill={colors.success.main} />,
  STOPPED: <AlertMinusCircleIcon fill={colors.text.main} />,
};
