export const ASSET_POSITION = {
  FRONT: "FRONT",
  FRONT_LEFT: "FRONT_LEFT",
  FRONT_RIGHT: "FRONT_RIGHT",
  REAR: "REAR",
  REAR_LEFT: "REAR_LEFT",
  REAR_RIGHT: "REAR_RIGHT",
  LEFT: "LEFT",
  RIGHT: "RIGHT",
  SPHERICAL_CAM: "SPHERICAL_CAM",
  BASE: "BASE",
  UNKNOWN: "UNKNOWN",
};

export const STREAM_TYPE = {
  VIEWER: "VIEWER",
};
