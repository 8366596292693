import { instance, getAxiosConfig } from "../axios-config";
import { mapToAssetEvent, mapToAsset, mapToActiveWindows } from "./util";
import moment from "moment";
import { STREAM_TYPE } from "constants/assetPositions.ts";

export const getAssetOverview = async (assetId) => {
  const idToken = sessionStorage.getItem("token");

  if (!assetId || !idToken) {
    throw Error("Token required.");
  }

  const today = moment().startOf("day");
  const fromToday = today.valueOf();
  const fromWeek = today.subtract("7d").valueOf();
  const fromMonth = today.subtract("30d").valueOf();

  const responses = await Promise.all([
    instance.get(
      `/assets/${assetId}/events/summary`,
      getAxiosConfig({ idToken: idToken }, null, { from: fromToday })
    ),
    instance.get(
      `/assets/${assetId}/events/summary`,
      getAxiosConfig({ idToken: idToken }, null, { from: fromWeek })
    ),
    instance.get(
      `/assets/${assetId}/events/summary`,
      getAxiosConfig({ idToken: idToken }, null, {
        from: fromMonth,
      })
    ),
    instance.get(
      `/assets/${assetId}/events`,
      getAxiosConfig({ idToken: idToken }, null, {
        from: moment().subtract("100d").valueOf(),
        count: 500,
      })
    ),
  ]);

  const [dayEventSummaries, weekEventSummaries, monthEventSummaries, events] = responses;

  const mappedEvents = events.data.events
    .map((e) => mapToAssetEvent(e, {}))
    .sort((a, b) => b.eventTimestamp - a.eventTimestamp)
    .slice(0, 30);

  return {
    riskLevels: {
      /* TODO */ day: [],
      month: [],
      week: [],
    },
    recentEvents: mappedEvents,
    eventsSummaries: {
      day: {
        incidents: dayEventSummaries.data.numIncidents,
        warnings: dayEventSummaries.data.numWarnings,
      },
      week: {
        incidents: weekEventSummaries.data.numIncidents,
        warnings: weekEventSummaries.data.numWarnings,
      },
      month: {
        incidents: monthEventSummaries.data.numIncidents,
        warnings: monthEventSummaries.data.numWarnings,
      },
    },
  };
};

export const getAssetVideoHistory = async (assetId, streamArn, from, to) => {
  const idToken = sessionStorage.getItem("token");

  if (!assetId || !idToken) {
    console.log("Token required.");
  }

  let result = null;

  try {
    result = await instance.get(
      `/assets/${assetId}/video`,
      getAxiosConfig({ idToken: idToken }, null, {
        from: from,
        to: to,
        streamArn: streamArn,
      })
    );
  } catch (error) {
    console.log("error in getAssetVideoHistory", error);
  }

  if (result) {
    return result.data?.map((r) => {
      return {
        start: r.start,
        end: r.end,
        startFragment: r.startFragment,
        endFragment: r.endFragment,
        numberOfFragments: r.numberOfFragments,
        segmentEvents: r.segmentEvents.map((e) => mapToAssetEvent(e, {})),
      };
    });
  }
  return null;
};

export const getAssetVideoFeed = async (assetId, streamArn, startingFrom, ending, isChromium) => {
  const idToken = sessionStorage.getItem("token");

  if (!assetId || !idToken) {
    console.log("Token required.");
  }

  const expiresIn = 43200; // 12 hours

  let result = null;

  let apiUrl = null;

  if (isChromium) {
    apiUrl = `/assets/${assetId}/video/archive/dash`;
  } else {
    apiUrl = `/assets/${assetId}/video/archive/hls`;
  }

  try {
    result = await instance.get(
      apiUrl,
      getAxiosConfig({ idToken: idToken }, null, {
        from: startingFrom,
        to: ending,
        streamArn,
        expiresIn,
      })
    );
  } catch (error) {
    console.log("error in getAssetVideoFeed", error);
  }

  return result.data;
};

export const getAssets = async (orgId) => {
  const idToken = sessionStorage.getItem("token");

  if (!orgId || !idToken) {
    throw Error("Org id and token required.");
  }

  const assets = await instance.get(
    `/org/${orgId}/assets/cards`,
    getAxiosConfig({ idToken: idToken })
  );

  return {
    assets: assets.data.map((a) => {
      return {
        asset: mapToAsset(a.asset),
        numWarnings: a.eventInfo.numWarnings,
        numIncidents: a.eventInfo.numIncidents,
        numNearMisses: a.eventInfo.numNearMisses,
        activeWindows: a.eventInfo.activeWindows.map(mapToActiveWindows),
      };
    }),
  };
};

export const getAsset = async (assetId) => {
  const idToken = sessionStorage.getItem("token");

  if (!idToken) {
    console.log("Token required.");
  }

  if (!assetId) {
    console.log("Asset id required");
  }

  let assetResponse = null;

  try {
    assetResponse = await instance.get(
      `/test/assets/${assetId}`,
      getAxiosConfig({ idToken: idToken })
    );
  } catch (error) {
    console.log("error", error);
  }

  let asset = null;

  if (assetResponse) {
    asset = mapToAsset(assetResponse.data.asset);
    const streams = assetResponse.data?.assetStreams;

    asset.videoStreamInfo =
      Object.entries(streams).map(([k, v]) => {
        return {
          position: k,
          streamStatus: v.dozerProcessingStatus || null,
          kinesisStreamArn: v.kinesisStreamArn,
        };
      }) || [];
  }

  return asset;
};

export const getMonthlyVideoInfo = async (assetId, streamArn, from, to, timezone) => {
  const idToken = sessionStorage.getItem("token");

  if (!idToken) {
    console.log("Token required.");
  }

  if (!assetId) {
    console.log("Asset id required");
  }

  let result = null;

  try {
    result = await instance.get(
      `/assets/${assetId}/video/monthly`,
      getAxiosConfig({ idToken: idToken }, null, {
        from,
        to,
        streamArn,
        timeframeTimeZone: timezone,
      })
    );
  } catch (error) {
    console.log("error", error);
  }

  if (result) {
    return result.data;
  }

  return null;
  // return result.data.map(res => ({
  //     dayTimestamp: res.dayTimestamp,
  //     eventLevels: res.eventLevels
  // }));
};

export const getAssetLiveVideoFeed = async (assetId, streamArn, startingFrom) => {
  const expiresIn = 43200; // 12 hours

  const idToken = sessionStorage.getItem("token");

  if (!idToken) {
    console.log("Token required.");
  }

  if (!assetId) {
    console.log("Asset id required");
  }

  let result = null;

  try {
    result = await instance.get(
      `/assets/${assetId}/video/live`,
      getAxiosConfig({ idToken: idToken }, null, {
        from: startingFrom, // should be start of day, in UTC?
        // to,
        streamArn,
        expiresIn,
      })
    );
  } catch (error) {
    console.log("error", error);
  }

  return result.data;
};
