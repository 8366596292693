import moment from "moment/moment";
import { mapToAssetEvent, equipmentCountOf, mapJobSite } from "./util";
import { instance, getAxiosConfig } from "../axios-config";
import { useAuth0 } from "@auth0/auth0-react";

export async function getUserByAuthId(authId, idToken) {
  try {
    const response = await instance.get(
      `/users/auth/${encodeURI(authId)}`,
      getAxiosConfig({
        idToken: idToken,
      })
    );
    return {
      ...response.data,
      idToken: idToken,
    };
  } catch (error) {
    console.log(error);
    return null;
  }
}

export async function signup(request, idToken) {
  let response;

  try {
    response = await instance.post(
      "/register",
      request,
      getAxiosConfig({
        idToken: idToken,
      })
    );
  } catch (error) {
    return {
      status: error?.response?.status,
      message: error,
    };
  }

  return {
    user: response.data,
    status: response.status,
  };
}

export async function getDashboardData(orgID) {
  const idToken = sessionStorage.getItem("token");

  if (!orgID || !idToken) {
    throw Error("Org id and token required.");
  }

  try {
    const fromToday = moment().startOf("day").valueOf();

    const axiosConfig = getAxiosConfig({ idToken: idToken });

    const orgEvents = await instance.get(
      `/org/${orgID}/events`,
      getAxiosConfig({ idToken: idToken }, null, {
        from: moment().valueOf(),
        count: 10,
      })
    );

    const jobsites = await instance.get(`/org/${orgID}/jobsites/cards`, axiosConfig);

    const assets = await instance.get(`/org/${orgID}/assets`, axiosConfig);

    const orgEventSummary = await instance.get(
      `/org/${orgID}/events/summary`,
      getAxiosConfig({ idToken: idToken }, null, {
        from: fromToday,
        to: moment().endOf("day").valueOf(),
      })
    );

    const assetIdToAsset = {};

    for (const asset of assets.data) {
      assetIdToAsset[asset.assetId] = asset;
    }

    return {
      idleEquipment: equipmentCountOf(assets.data, (a) => !a.isActive),
      activeEquipment: equipmentCountOf(assets.data, (a) => a.isActive),
      activeJobsites: jobsites.data
        .filter((j) => j.jobsite.isActive)
        .map((j) => mapJobSite(j.jobsite)),
      incidentsToday: orgEventSummary.data.numIncidents,
      recentEvents: orgEvents.data.events.map((e) => mapToAssetEvent(e, assetIdToAsset)),
    };
  } catch (error) {
    if (error?.response?.status === 403) {
      throw "unauthorized";
    }

    throw error;
  }
}
