import { useState, useEffect } from "react";
import SoftTypography from "components/SoftTypography";
import TimelineList from "examples/Timeline/TimelineList";
import TimelineItem from "examples/Timeline/TimelineItem";
import { Grid, Typography, Tabs, Tab, Box, Button } from "@mui/material";
import PropTypes from "prop-types";
import moment from "moment";
import {
  CheckCircleIconOutline,
  AlertCircleIconOutline,
  AlertDecagramIconOutline,
} from "assets/images/icons/Icons.tsx";
import LineChart from "examples/Charts/LineCharts/GradientLineChart";
import DozerTimelineItem from "examples/Timeline/DozerTimelineItem";
import colors from "assets/theme/base/colors";
import DozerTable from "examples/Tables/DozerTable";
import { mapEquipmentToRows } from "data/api/util";
import { DataGrid } from "@mui/x-data-grid";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import DozerEquipmentTableCell from "examples/Tables/DozerTable/DozerEquipmentTableCell";
import { NavLink } from "react-router-dom";

const JobsiteAssets = ({ equipment }) => {
  const [equipmentRows, setEquipmentRows] = useState([]);

  const mapEquipmentToRows = (equipment) => {
    const rows = equipment.map((asset) => {
      return {
        id: asset.assetId,
        name: asset.nickName,
        status: asset.isActive,
        model: asset.model,
        type: asset.typeInfo.type,
        iconUrl: asset.typeInfo.iconUrl,
      };
    });

    setEquipmentRows(rows);
  };

  const columns = [
    {
      field: "name",
      headerName: "NAME",
      width: 250,
      renderCell: (params) => (
        <DozerEquipmentTableCell
          key={params.row.id}
          content={`${params.row.name}`}
          image={params.row.iconUrl}
          noBorder={true}
          isActive={params.row.status}
        />
      ),
    },
    { field: "status", headerName: "STATUS", width: 150 },
    { field: "model", headerName: "MODEL", width: 150 },
    { field: "type", headerName: "TYPE", width: 150 },
    {
      field: "goToAsset",
      headerName: "",
      description: "This column has a value getter and is not sortable.",
      sortable: false,
      filterable: false,
      flex: 1,
      renderCell: (params) => (
        <NavLink style={{ display: "flex" }} to={`/assets/${params.row.id}`}>
          <Button sx={{ height: "30px" }} component="label" variant="outlined">
            GO TO ASSET
          </Button>
        </NavLink>
      ),
    },
  ];

  useEffect(() => {
    if (equipment?.length) {
      mapEquipmentToRows(equipment);
    }
  }, [equipment]);

  return (
    <Box paddingLeft={6} paddingRight={6} sx={{ height: 500, width: "100%" }}>
      <DataGrid
        initialState={{
          columns: {
            columnVisibilityModel: {
              // Hide columns
              status: false,
            },
          },
        }}
        sx={{
          border: 0,
          "& .MuiInputBase-root": {
            width: "auto!important",
          },
          [`& .MuiDataGrid-withBorderColor`]: {
            border: 0,
          },
          "& .MuiDataGrid-columnHeaderTitle": {
            color: colors.dozer.gray.light,
            fontWeight: 400,
          },
          "& .MuiDataGrid-menuIcon svg": {
            fill: colors.dozer.gray.light,
          },
          "& .MuiDataGrid-cellContent": {
            color: colors.dozer.gray.medium,
            fontWeight: 500,
          },
          "& .MuiDataGrid-row.Mui-selected": {
            background: colors.dozer.alphaYellow,
            "&:hover": {
              background: colors.dozer.alphaYellow,
            },
          },
          "& .MuiDataGrid-cell:focus-within": {
            outline: `solid ${colors.dozer.darkYellow} 1px`,
            outlineWidth: "1px",
            outlineOffset: "-1px",
          },
        }}
        disableColumnSelector
        rows={equipmentRows}
        columns={columns}
      />
    </Box>
  );
};

export default JobsiteAssets;

JobsiteAssets.defaultProps = {
  equipment: [],
};

JobsiteAssets.propTypes = {
  equipment: PropTypes.arrayOf(PropTypes.object).isRequired,
};
