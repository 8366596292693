import SoftTypography from "components/SoftTypography";
import PropTypes from "prop-types";

const DozerStatLabel = ({ statName, statValue, color }) => {
  return (
    <>
      <SoftTypography
        variant="subtitle2"
        color="text"
        opacity={0.7}
        textTransform="uppercase"
        fontWeight="bold"
      >
        {statName}
      </SoftTypography>

      <SoftTypography
        variant="h1"
        opacity={0.7}
        textTransform="uppercase"
        fontWeight="bold"
        color={color}
      >
        {statValue}
      </SoftTypography>
    </>
  );
};

export default DozerStatLabel;

DozerStatLabel.propTypes = {
  statName: PropTypes.string,
  statValue: PropTypes.number,
  color: PropTypes.string,
};
