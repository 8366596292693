/**
=========================================================
* Soft UI Dashboard PRO React - v4.0.1
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import TableCell from "@mui/material/TableCell";

// Soft UI Dashboard PRO React components
import SoftTypography from "components/SoftTypography";
import SoftBox from "components/SoftBox";
import React from "react";
import { wrap } from "lodash";
import { Grid } from "@mui/material";

function DozerEquipmentTableCell({ content, image, imageHeight, noBorder, isActive, ...rest }) {
  let template;

  if (image) {
    template = (
      <Grid item align="left" sx={{ width: "100%", border: noBorder && 0 }}>
        <SoftBox display="flex" alignItems="center">
          <SoftBox
            component="img"
            src={image}
            alt={content}
            width="auto"
            height={imageHeight ?? "1.5rem"}
          />
          <SoftTypography
            noWrap
            width="100%"
            ml={2}
            variant="subtitle2"
            fontWeight="medium"
            textTransform="capitalize"
            color={isActive && "success"}
            sx={{ textOverflow: "ellipsis" }}
          >
            {content}
          </SoftTypography>
        </SoftBox>
      </Grid>
    );
  } else {
    template = (
      <TableCell {...rest} align="center" sx={{ border: noBorder && 0 }}>
        <SoftBox display="flex" flexDirection="column">
          <SoftTypography variant="button" fontWeight="medium" textTransform="capitalize">
            {content}
          </SoftTypography>
        </SoftBox>
      </TableCell>
    );
  }

  return template;
}

// Setting default values for the props of SalesTableCell
DozerEquipmentTableCell.defaultProps = {
  image: "",
  imageHeight: undefined,
  noBorder: false,
  isActive: false,
};

// Typechecking props for the SalesTableCell
DozerEquipmentTableCell.propTypes = {
  content: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  image: PropTypes.string,
  imageHeight: PropTypes.number,
  noBorder: PropTypes.bool,
  isActive: PropTypes.bool,
};

export default DozerEquipmentTableCell;
